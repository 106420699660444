import React, { useCallback, useState } from 'react';
import {
  PropertyFragmentFragment,
  useAddPropertyPhotoMutation,
  useConfirmPropertyPhotoUploadMutation,
} from '../../../_generated/graphql';
import ImageList from '@mui/material/ImageList';
import { useAlert } from '@snuglyrent/react-alert';
import { ImageListItem, ImageListItemBar, Stack, useMediaQuery, useTheme } from '@mui/material';
import { UploadButton } from '../../../components/upload-button';
import { uploadFiles } from '../../../lib/upload-files';

interface PropertyPhotoSectionProps {
  property: PropertyFragmentFragment;
  onUploadCompleted: () => void;
  onSelectPhoto: (idx: number) => void;
}

export function PropertyPhotoSection({ property, onUploadCompleted, onSelectPhoto }: PropertyPhotoSectionProps) {
  const theme = useTheme();
  const { show } = useAlert();
  const isLargerThanXs = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('lg'));

  const photoHeight = isLargerThanXs ? 200 : 150;

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [addPropertyPhoto] = useAddPropertyPhotoMutation();
  const [confirmUpload] = useConfirmPropertyPhotoUploadMutation();

  const handleFileUpload = useCallback(
    async (files: FileList) => {
      setUploadInProgress(true);

      try {
        await uploadFiles(
          files,
          (file) =>
            addPropertyPhoto({
              variables: { id: property.id, input: { filename: file.name, mimeType: file.type, size: file.size } },
            }),
          (addData) => addData.addPropertyPhoto.uploadUrl,
          (file, addData) =>
            confirmUpload({
              variables: { id: property.id, photoId: addData.addPropertyPhoto.fileId, size: file.size },
            }),
        );
        onUploadCompleted();
      } catch (error) {
        show.error('Chyba pri nahrávaní fotky');
      } finally {
        setUploadInProgress(false);
      }
    },
    [addPropertyPhoto, onUploadCompleted, property.id, confirmUpload, show],
  );

  return (
    <Stack spacing={1}>
      <ImageList sx={{ width: '100%' }} cols={isLargerThanXs ? (isLargerThanMd ? 10 : 5) : 3} rowHeight={photoHeight}>
        {property.photos.map((p, idx) => (
          <ImageListItem key={p.id} sx={{ '& img': { height: '100% !important', objectFit: 'contain !important' } }}>
            <img
              src={p.url}
              alt={p.desc ?? undefined}
              loading="lazy"
              onClick={(e) => {
                e.preventDefault();
                onSelectPhoto(idx);
              }}
            />
            <ImageListItemBar title={p.desc ?? undefined} position="below" />
          </ImageListItem>
        ))}
      </ImageList>
      <UploadButton
        label="Pridaj fotku"
        inProgress={uploadInProgress}
        sx={{ alignSelf: 'flex-start' }}
        onUpload={handleFileUpload}
      />
    </Stack>
  );
}
