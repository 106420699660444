/**
 * Promise wrapper for XMLHttpRequest upload
 * @param file
 * @param url
 * @param listeners event listeners: onLoadStart, onLoad, onLoadEnd, onProgress, onError, onAbort
 * @returns http status code, 200 if ok
 */
export function uploadXHR(
  file: File,
  url: string,
  listeners?: {
    onLoadStart?: (e?: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    onLoad?: (e?: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    onLoadEnd?: (e?: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    onProgress?: (e?: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    onError?: (e?: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    onAbort?: (e?: ProgressEvent<XMLHttpRequestEventTarget>) => void;
  },
): Promise<number> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve(xhr.status);
      }
    };
    if (listeners?.onLoadStart) {
      xhr.addEventListener('loadstart', listeners.onLoadStart);
    }
    if (listeners?.onLoad) {
      xhr.addEventListener('load', listeners.onLoad);
    }
    if (listeners?.onLoadEnd) {
      xhr.addEventListener('loadend', listeners.onLoadEnd);
    }
    if (listeners?.onProgress) {
      xhr.addEventListener('progress', listeners.onProgress);
    }
    if (listeners?.onError) {
      xhr.addEventListener('error', listeners.onError);
    }
    if (listeners?.onAbort) {
      xhr.addEventListener('abort', listeners.onAbort);
    }
    xhr.send(file);
  });
}
