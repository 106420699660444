import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';

import React, { FormEvent } from 'react';

interface SignInFormProps {
  onSubmit: (username: string, password: string) => void;
}

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
  password_repeat: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export function SignUpForm(props: SignInFormProps) {
  return (
    <form
      onSubmit={(event: FormEvent<SignInFormElement>) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
          username: formElements.username.value,
          password: formElements.password.value,
          passwordRepeat: formElements.password_repeat.value,
        };
        props.onSubmit(data.username, data.password);
      }}
    >
      <FormControl required>
        <FormLabel>Username/E-mail</FormLabel>
        <Input name="username" />
      </FormControl>
      <FormControl required>
        <FormLabel>Password</FormLabel>
        <Input name="password" type="password" />
      </FormControl>
      <FormControl required>
        <FormLabel>Repeat password</FormLabel>
        <Input name="password_repeat" type="password" />
      </FormControl>

      <Button type="submit" fullWidth variant="contained">
        Sign up
      </Button>
    </form>
  );
}
