import React from 'react';
import { ContractFragmentFragment } from '../../../_generated/graphql';
import Grid from '@mui/material/Unstable_Grid2';
import { FormInput } from '../../../components/form-input';

interface InputContactSegmentProps {
  contract: ContractFragmentFragment;
  onUpdate: (e: React.ChangeEvent) => void;
}

export function InputContactSegment(props: InputContactSegmentProps) {
  const { contract: c, onUpdate } = props;

  return (
    <>
      <Grid xs={12} sm={6} lg={4}>
        <FormInput name="contactName" label="Meno" value={c?.contactName} onChange={onUpdate} />
      </Grid>
      <Grid xs={12} sm={6} lg={4}>
        <FormInput name="contactPhone" label="Telefón" value={c?.contactPhone} onChange={onUpdate} />
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <FormInput name="contactEmail" label="E-mail" value={c?.contactEmail} onChange={onUpdate} />
      </Grid>
    </>
  );
}
