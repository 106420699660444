import React from 'react';
import { PropertyListFragmentFragment } from '../../../_generated/graphql';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface AddPropertyDialogProps {
  properties: PropertyListFragmentFragment[];
  selected: PropertyListFragmentFragment[];
  onSubmit: (propertyId: string) => void;
  onClose: () => void;
}

export function AddPropertyDialog(props: AddPropertyDialogProps) {
  const { properties, selected, onSubmit, onClose } = props;

  return (
    <Dialog open sx={{ minWidth: 350 }}>
      <DialogTitle>Vyber službu</DialogTitle>
      <DialogContent>
        {properties
          .filter((p) => selected.findIndex((s) => s.id === p.id) < 0)
          .map((p) => (
            <Button key={p.id} onClick={() => onSubmit(p.id)} fullWidth variant="text">
              {p.name}
            </Button>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          Zatvoriť
        </Button>
      </DialogActions>
    </Dialog>
  );
}
