import { FormLabel, Stack, Typography } from '@mui/material';
import React from 'react';
interface LabelAndValueProps {
  label: string;
  value: string | number | undefined;
  split?: number | string;
}

export function LabelAndValue(props: LabelAndValueProps) {
  const { label, value, split = 0.5 } = props;
  return (
    <Stack direction="row" spacing={2}>
      <FormLabel sx={{ width: split }}>{label}</FormLabel>
      <Typography variant="body1">{value ?? ''}</Typography>
    </Stack>
  );
}
