import React, { createContext, ReactNode, useCallback, useContext, useReducer } from 'react';

export type AlertVariant = 'info' | 'error' | 'fatal';

export interface AlertType {
  id: number;
  message: string;
  details?: string;
  variant?: AlertVariant;
}

export type AlertContextType = {
  alerts: AlertType[];
  show: {
    error: (msg: string, details?: string) => void;
    fatal: (msg: string, details?: string) => void;
    info: (msg: string, details?: string) => void;
  };
  remove: (id: number) => void;
  clear: () => void;
};

export const AlertContext = createContext<AlertContextType>({
  alerts: [],
  show: { error: () => null, fatal: () => null, info: () => null },
  remove: () => null,
  clear: () => null,
});

export const useAlert = (): AlertContextType => useContext(AlertContext);

interface AlertAction {
  type: string;
  alert?: AlertType;
  id?: number;
}

function dispatchAlert(state: AlertType[], action: AlertAction): AlertType[] {
  switch (action.type) {
    case 'ADD':
      if (action.alert) return [...state, action.alert];
      return state;

    case 'REMOVE': {
      const a = [...state];
      const i = a.findIndex((ac) => ac.id === action.id);
      if (i >= 0) a.splice(i, 1);
      return a;
    }
    case 'CLEAR':
      return [];
    default:
      console.error('DispatchAlert: Unknown action type', action.type);
  }
  return [];
}

interface AlertProviderProps {
  children?: ReactNode;
  autoClose?: {
    info?: number;
    error?: number;
    fatal?: number;
  };
}

/**
 * Component that provides alert functionality to the app.
 * App must be wrapped in this component.
 * @param autoClose Timeout in ms for closing each alert type. Default: { info: 5000, error: 5000, fatal: 30000 }
 * @returns
 */
export const AlertProvider = (props: AlertProviderProps): JSX.Element => {
  const { children, autoClose } = props;
  const infoTimeout = autoClose?.info ?? 5000;
  const errorTimeout = autoClose?.error ?? 5000;
  const fatalTimeout = autoClose?.fatal ?? 30000;
  const [state, dispatch] = useReducer(dispatchAlert, []);

  const clearAlerts = useCallback(() => dispatch({ type: 'CLEAR' }), []);
  const removeAlert = useCallback((id: number) => dispatch({ type: 'REMOVE', id }), []);

  const alertError = useCallback(
    (msg: string, details?: string) => {
      const id = Date.now() + errorTimeout;
      dispatch({
        type: 'ADD',
        alert: { id, message: msg, details, variant: 'error' },
      });
      setTimeout(() => removeAlert(id), errorTimeout);
    },
    [errorTimeout, removeAlert],
  );

  const alertFatal = useCallback(
    (msg: string, details?: string) => {
      const id = Date.now() + fatalTimeout;
      dispatch({
        type: 'ADD',
        alert: { id, message: msg, details, variant: 'fatal' },
      });
      setTimeout(() => removeAlert(id), fatalTimeout);
    },
    [fatalTimeout, removeAlert],
  );

  const alertInfo = useCallback(
    (msg: string, details?: string) => {
      const id = Date.now() + infoTimeout;
      dispatch({
        type: 'ADD',
        alert: { id, message: msg, details, variant: 'info' },
      });
      setTimeout(() => removeAlert(id), infoTimeout);
    },
    [infoTimeout, removeAlert],
  );

  return (
    <AlertContext.Provider
      value={{
        alerts: state,
        show: { error: alertError, fatal: alertFatal, info: alertInfo },
        remove: removeAlert,
        clear: clearAlerts,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
