import React, { useState } from 'react';
import { PropertyFragmentFragment } from '../../../_generated/graphql';
import { AppBar, Button, Dialog, FormLabel, IconButton, Input, Paper, Stack, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface PropertyPictureViewProps {
  property: PropertyFragmentFragment;
  selectedPhoto: number;
  onClose: () => void;
  onDeletePhoto: (photoId: string) => void;
  onSetAsCoverPhoto: (photoId: string) => void;
  onCaptionChange: (photoId: string, caption: string) => void;
}

export function PropertyPictureView(props: PropertyPictureViewProps) {
  const { property, selectedPhoto, onClose, onSetAsCoverPhoto, onDeletePhoto, onCaptionChange } = props;
  const [currentIndex, setCurrentIndex] = useState(selectedPhoto);
  const [caption, setCaption] = useState(property?.photos[currentIndex].desc);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const photos = property?.photos || [];

  const handlePrev = () => {
    const idx = currentIndex > 0 ? currentIndex - 1 : photos.length - 1;
    setCurrentIndex(idx);
    setCaption(photos[idx].desc);
  };

  const handleNext = () => {
    const idx = currentIndex < photos.length - 1 ? currentIndex + 1 : 0;
    setCurrentIndex(idx);
    setCaption(photos[idx].desc);
  };

  const handleUpdateCaption = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCaption(e.target.value);
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        onCaptionChange(photos[currentIndex].id, e.target.value);
      }, 500),
    );
  };

  return (
    <Dialog fullScreen open={true}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton edge="end" color="inherit" aria-label="more">
              <MoreVertIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
        <div style={{ marginBottom: '20px' }}>
          <Button variant="contained" color="primary" onClick={handlePrev}>
            Predchádzajúci
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => onSetAsCoverPhoto(photos[currentIndex].id)}
            style={{ marginLeft: '10px' }}
          >
            Nastav ako hlavnú
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onDeletePhoto(photos[currentIndex].id)}
            style={{ marginLeft: '10px' }}
          >
            Zmaž
          </Button>

          <Button variant="contained" color="primary" onClick={handleNext} style={{ marginLeft: '10px' }}>
            Ďalší
          </Button>
        </div>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
          <FormLabel>Popis</FormLabel>
          <Input value={caption ?? ''} placeholder="tu môžete zadať popis fotky..." onChange={handleUpdateCaption} />
        </Stack>
        <br />
        <img
          src={photos[currentIndex].url}
          height={500}
          alt={photos[currentIndex].desc ?? undefined}
          style={{ maxHeight: '100%', maxWidth: '100%' }}
        />
      </Paper>
    </Dialog>
  );
}
