import axios, { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useEffect, useState } from 'react';

export interface AuthUser {
  id?: string;
  username: string;
  token: string;
}

export type AuthError = 'UNAUTHORIZED' | 'FORBIDDEN' | 'FAILED_TO_FETCH' | 'SIGNUP_FAILED';

export interface AuthContextData<T extends AuthUser = AuthUser> {
  isLoading: boolean;
  user?: (AuthUser & T) | null;
  error?: AuthError;
  isSelf: (userId: string) => boolean;
  login: (username: string, password: string, persist?: boolean) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  token?: string | null;
  getAuthHeader: () => string | undefined;
  signup: (username: string, password: string) => Promise<boolean>;
}

const emptyContext: AuthContextData = {
  isLoading: false,
  isAuthenticated: false,
  isSelf: () => false,
  login: (username, password, persist) => Promise.reject(new Error('Not initialized')),
  logout: () => Promise.reject(new Error('Not initialized')),
  getAuthHeader: () => undefined,
  signup: (username, password) => Promise.reject(new Error('Not initialized')),
};

export const AuthContext = createContext<AuthContextData>(emptyContext);

interface AuthContextProviderProps<T extends AuthUser = AuthUser> {
  children?: React.ReactNode;
  authEndpoint: string;
  onLogin?: (user: T, persist?: boolean) => void;
  onError?: (error: AuthError) => void;
  onLogout?: () => void;
  token?: string | null;
}

let tokenRefreshTimer: NodeJS.Timeout | undefined = undefined;
const tokenRefreshInterval = 12 * 60 * 60_000;

const startTokenRefreshProcess = (oldToken: string, loginFn: (token: string) => unknown) => {
  tokenRefreshTimer = setTimeout(() => {
    loginFn(oldToken);
  }, tokenRefreshInterval);
};

export function AuthContextProvider<T extends AuthUser = AuthUser>(props: AuthContextProviderProps<T>) {
  const { children, authEndpoint, onLogin, onLogout, token: oldToken, onError } = props;
  const [userLoading, setUserLoading] = useState(oldToken !== null);
  const [authError, setAuthError] = useState<AuthError | undefined>(undefined);
  const [userData, setUserData] = useState<T | null>(null);

  const processLoginResponse = useCallback(
    async function (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response: AxiosResponse<T, any>,
      persist?: boolean,
    ): Promise<void> {
      let error: AuthError | undefined = undefined;
      if (response.status === 401) {
        error = 'UNAUTHORIZED';
      } else if (response.status !== 200) {
        error = 'FAILED_TO_FETCH';
      }

      setAuthError(error);
      if (error) {
        setUserData(null);
        if (onError) onError(error);
        return;
      }

      if (!response.data.username || !response.data.token) {
        return setUserData(null);
      }

      setUserData(response.data);

      if (onLogin) onLogin(response.data, persist);
    },
    [onError, onLogin],
  );

  const silentLogin = useCallback(
    async function (t: string): Promise<void> {
      setUserLoading(true);
      try {
        const response = await axios.post<T>(`${authEndpoint}/login`, {
          token: t ?? null,
        });

        startTokenRefreshProcess(response.data.token, silentLogin);
        await processLoginResponse(response, true);
      } catch (e) {
        setAuthError('UNAUTHORIZED');
        setUserData(null);
      }
      setUserLoading(false);
    },
    [authEndpoint, processLoginResponse],
  );

  const login = useCallback(
    async function (username: string, password: string, persist?: boolean): Promise<void> {
      setUserLoading(true);
      console.log('login', username, password, persist);
      try {
        const response = await axios.post(`${authEndpoint}/login`, {
          username,
          password,
          stay_signed_in: persist,
        });

        startTokenRefreshProcess(response.data.token, silentLogin);
        await processLoginResponse(response, persist);
      } catch (e) {
        setAuthError('UNAUTHORIZED');
        setUserData(null);
        if (onError) onError('UNAUTHORIZED');
      }
      setUserLoading(false);
    },
    [authEndpoint, onError, processLoginResponse, silentLogin],
  );

  const signup = useCallback(
    async function (username: string, password: string): Promise<boolean> {
      let response: AxiosResponse | undefined = undefined;
      setUserLoading(true);
      try {
        response = await axios.post(`${authEndpoint}/signup`, {
          username,
          password,
        });
      } catch (e) {
        if (onError) onError('SIGNUP_FAILED');
      }
      setUserLoading(false);
      if (response && response.status === 201) {
        await login(username, password);
        return true;
      }

      return false;
    },

    [authEndpoint, login, onError],
  );

  const logout = useCallback(
    async function (): Promise<void> {
      if (!userData) return;
      const username = userData?.username;
      const token = userData?.token;
      // jsut fire and forget

      axios.post(`${authEndpoint}/logout`, { username, token }).catch(() => {
        /*do nothing on error*/
      });
      setUserData(null);
      clearTimeout(tokenRefreshTimer);
      if (onLogout) onLogout();
    },
    [authEndpoint, onLogout, userData],
  );

  useEffect(() => {
    if (!userData && oldToken) {
      silentLogin(oldToken);
    }
  }, [silentLogin, oldToken, userData]);

  return (
    <AuthContext.Provider
      value={
        {
          user: userData ?? null,
          error: authError,
          isLoading: userLoading,
          isSelf: (id) => id === userData?.id,
          isAuthenticated: !!userData?.token,
          login,
          logout,
          getAuthHeader: () => {
            if (!userData?.token) return undefined;
            return `Bearer ${userData.token}`;
          },
          signup,
        } as AuthContextData<T>
      }
    >
      {children}
    </AuthContext.Provider>
  );
}
