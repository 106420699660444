import React, { useCallback, useState } from 'react';

import {
  FileRefDocType,
  FileRefFragmentFragment,
  useAddFileToPropertyMutation,
  useConfirmFileUploadedToPropertyMutation,
  useDeleteFileFromPropertyMutation,
} from '../../../_generated/graphql';
import { FileRefList } from '../../../components/fileref-list';
import { UploadButton } from '../../../components/upload-button';
import { uploadFiles } from '../../../lib/upload-files';
import { useAlert } from '@snuglyrent/react-alert';

interface PropertyFilesSectionProps {
  propertyId: string;
  files: FileRefFragmentFragment[];
  type: FileRefDocType;
  uploadButtonLabel: string;
  onUploadCompleted: () => void;
}

export function PropertyFilesSection({
  propertyId,
  files,
  type,
  uploadButtonLabel,
  onUploadCompleted,
}: PropertyFilesSectionProps) {
  const { show } = useAlert();
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [deleteFile] = useDeleteFileFromPropertyMutation();

  const [addFile] = useAddFileToPropertyMutation();
  const [confirmUpload] = useConfirmFileUploadedToPropertyMutation();

  const handleFileUpload = useCallback(
    async (files: FileList) => {
      setUploadInProgress(true);

      try {
        await uploadFiles(
          files,
          (file) =>
            addFile({
              variables: { id: propertyId, type, input: { filename: file.name, mimeType: file.type, size: file.size } },
            }),
          (addData) => addData.addFileToProperty.uploadUrl,
          (file, addData) =>
            confirmUpload({
              variables: { id: propertyId, fileId: addData.addFileToProperty.fileId, size: file.size },
            }),
        );
        onUploadCompleted();
      } catch (error) {
        show.error('Chyba pri nahrávaní fotky');
      } finally {
        setUploadInProgress(false);
      }
    },
    [addFile, onUploadCompleted, propertyId, type, confirmUpload, show],
  );

  return (
    <>
      <FileRefList files={files} onDelete={(f) => deleteFile({ variables: { id: propertyId, fileId: f.id } })} />
      <UploadButton label={uploadButtonLabel} inProgress={uploadInProgress} onUpload={handleFileUpload} />
    </>
  );
}
