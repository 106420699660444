import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HouseIcon from '@mui/icons-material/House';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useLocation, useNavigate } from 'react-router-dom';
import { appPaths } from '../app-paths';
interface MainNavbarProps {
  onMobileNavigationClosed: () => void;
  mobileNavigationOpen: boolean;
}

const navList: { path: string; label: string; icon: React.ReactNode }[] = [
  { path: appPaths.dashboard(), label: 'Prehľad', icon: <DashboardIcon /> },
  { path: appPaths.propertyList(), label: 'Nehnuteľnosti', icon: <HouseIcon /> },
  { path: appPaths.contractList(), label: 'Prenájmy', icon: <HandshakeIcon /> },
];

export function MainNavbar(props: MainNavbarProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const navigate = useNavigate();
  const location = useLocation();

  const navigationMenu = (
    <List>
      {navList.map((item) => (
        <ListItem key={item.path} disablePadding>
          <ListItemButton
            onClick={() => navigate(item.path)}
            sx={{ px: 3, py: 1 }}
            selected={
              (item.path === '/' && location.pathname === '/') ||
              (item.path !== '/' && location.pathname.startsWith(item.path))
            }
          >
            <ListItemIcon sx={{ minWidth: '2.5em' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return isDesktop ? (
    <Paper sx={{ minWidth: 240, borderWidth: 0, borderRightWidth: 1, height: '100%' }} square variant="outlined">
      {navigationMenu}
    </Paper>
  ) : (
    <Drawer
      variant="temporary"
      open={props.mobileNavigationOpen}
      onClose={props.onMobileNavigationClosed}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Toolbar />
      {navigationMenu}
    </Drawer>
  );
}
