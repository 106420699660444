import React from 'react';
import { IconButton, Stack } from '@mui/material';

import { FileRefFragmentFragment } from '../_generated/graphql';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { dynamicFileSize } from '../lib/dynamic-file-size';

interface FileRefListProps {
  files: FileRefFragmentFragment[];
  onDelete: (file: FileRefFragmentFragment) => void;
}

export function FileRefList(props: FileRefListProps) {
  const { files, onDelete } = props;

  return (
    <Stack direction="row" spacing={2} flexWrap="wrap">
      {files
        .filter((f) => !f.deletedAt)
        .map((f) => (
          <FileRefListItem key={f.id} file={f} onDelete={onDelete} />
        ))}
    </Stack>
  );
}

interface FileRefListItemProps {
  file: FileRefFragmentFragment;
  onDelete: (file: FileRefFragmentFragment) => void;
}

function FileRefListItem(props: FileRefListItemProps) {
  const { file, onDelete } = props;

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <a href={file.url} target="_blank" rel="noreferrer" download={file.name}>
        {file.name}
      </a>
      <div>{dynamicFileSize(file.size)}</div>
      <IconButton onClick={() => onDelete(file)}>
        <HighlightOffIcon />
      </IconButton>
    </Stack>
  );
}
