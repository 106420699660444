import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';

interface ConfirmationDialogProps {
  loading: boolean;
  title: string;
  content: string;
  cancelLabel: string;
  confirmLabel: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ConfirmationDialog({
  loading,
  title,
  content,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) {
  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button disabled={loading} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </DialogActions>
      {loading && <LinearProgress />}
    </Dialog>
  );
}
