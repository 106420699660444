import React, { useMemo } from 'react';
import { useGetContractsQuery, useGetPropertiesQuery } from '../../_generated/graphql';
import { BasePage } from '../../components/base-page';
import { Stack, Typography } from '@mui/material';
import { PropertyCard } from '../../components/property-card';
import { useNavigate } from 'react-router-dom';
import { appPaths } from '../../app-paths';
import { ContractCard } from '../../components/contract-card';

export function DashboardPage() {
  const navigate = useNavigate();
  const { data: contData } = useGetContractsQuery();
  const { data: propData } = useGetPropertiesQuery();

  const potentialContracts = useMemo(
    () => (contData?.getContracts ?? []).filter((c) => !c.signingDate),
    [contData?.getContracts],
  );

  const currentContracts = useMemo(
    () => (contData?.getContracts ?? []).filter((c) => c.signingDate),
    [contData?.getContracts],
  );

  const availableProperties = useMemo(
    () =>
      (propData?.getProperties ?? []).filter(
        (p) => !currentContracts.some((c) => c.properties.some((cp) => cp.id === p.id)),
      ),
    [currentContracts, propData?.getProperties],
  );

  return (
    <BasePage>
      <Typography variant="h1">Prehľad</Typography>
      <Typography variant="h2">Záujemcovia</Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} useFlexGap flexWrap="wrap">
        {potentialContracts.length > 0 ? (
          potentialContracts.map((c) => (
            <ContractCard key={c.id} contract={c} onClick={(cc) => navigate(appPaths.contract(cc.id))} />
          ))
        ) : (
          <Typography variant="body1">Žiadni</Typography>
        )}
      </Stack>
      <Typography variant="h2">Neprenajaté nehnuteľnosti</Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} useFlexGap flexWrap="wrap">
        {availableProperties.length > 0 ? (
          availableProperties.map((p) => (
            <PropertyCard
              key={p.id}
              property={p}
              width={300}
              onEdit={() => navigate(appPaths.property(p.id))}
              onDelete={() => {}} // TODO: resolve during redesign
            />
          ))
        ) : (
          <Typography variant="body1">Žiadne</Typography>
        )}
      </Stack>

      <Typography variant="h2">Aktuálne prenájmy</Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} useFlexGap flexWrap="wrap">
        {currentContracts.length > 0 ? (
          currentContracts.map((c) => (
            <ContractCard key={c.id} contract={c} onClick={(cc) => navigate(appPaths.contract(cc.id))} />
          ))
        ) : (
          <Typography variant="body1">Žiadne</Typography>
        )}
      </Stack>
    </BasePage>
  );
}
