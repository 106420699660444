declare global {
  interface Window {
    NX_API_URL?: string | null;
  }
}

export const getAppConfig = () => {
  const config = {
    apiUrl: window.NX_API_URL ?? 'http://localhost:3000',
  };

  return config;
};
