export class DelayedWriter<T> {
  cache: Partial<T> = {};
  t: NodeJS.Timeout | null = null;
  writeFn: (data: Partial<T>) => void;
  delayMs: number;

  constructor(writeFn: (data: Partial<T>) => void, delayMs: number = 1000) {
    this.writeFn = writeFn;
    this.delayMs = delayMs;
  }

  write(data: Partial<T>) {
    if (this.t) {
      clearTimeout(this.t);
    }
    this.cache = { ...this.cache, ...data };
    this.t = setTimeout(() => {
      this.writeFn(this.cache);
      this.cache = {};
    }, this.delayMs);
  }
}
