import React from 'react';
import { PropertyListFragmentFragment } from '../../../_generated/graphql';
import { Chip, Stack } from '@mui/material';

interface PropertyListProps {
  properties: PropertyListFragmentFragment[];
  onRemove?: (propertyId: string) => void;
}

/**
 * Show properties as tags/chips
 * @param props properties
 * @returns
 */
export function PropertyList(props: PropertyListProps) {
  const { properties, onRemove } = props;

  return (
    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
      {properties.map((p) => (
        <Chip key={p.id} label={p.name} onDelete={onRemove ? () => onRemove(p.id) : undefined} />
      ))}
    </Stack>
  );
}
