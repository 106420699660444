import { FetchResult } from '@apollo/client';
import { uploadXHR } from './upload-xhr';

export async function uploadFiles<TAdd, TConfirm>(
  files: FileList,
  getUploadUrl: (file: File) => Promise<FetchResult<TAdd>>,
  getUploadUrlFromAddResult: (addData: TAdd) => string,
  confirmUpload: (file: File, addData: TAdd) => Promise<FetchResult<TConfirm>>,
) {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    // get upload URL
    const getUploadUrlResult = await getUploadUrl(file);

    if (getUploadUrlResult.data) {
      const uploadUrl = getUploadUrlFromAddResult(getUploadUrlResult.data);

      // upload to S3
      const uploadStatus = await uploadXHR(file, uploadUrl);

      if (uploadStatus === 200) {
        // confirm upload
        const confirmUploadResult = await confirmUpload(file, getUploadUrlResult.data);
        if (confirmUploadResult.errors) {
          throw Error('Error during upload confirmation');
        }
      } else {
        throw Error(`Error during upload of ${uploadUrl} to S3`);
      }
    } else {
      throw Error('Error during initial upload step');
    }
  }
}
