import React from 'react';
import { PropertyFragmentFragment } from '../_generated/graphql';
import { Box, Card, CardActionArea, CardHeader, CardMedia, IconButton } from '@mui/material';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface PropertyCardProps {
  property: PropertyFragmentFragment;
  width: number;
  onEdit: () => void;
  onDelete: () => void;
}

export function PropertyCard(props: PropertyCardProps) {
  const { property, onEdit, onDelete, width } = props;
  const photoHeight = 140;
  return (
    <Card sx={{ width }}>
      <CardActionArea onClick={onEdit}>
        {property.coverPhoto?.url ? (
          <CardMedia
            sx={{ height: photoHeight }}
            image={property.coverPhoto?.url}
            title={property.coverPhoto?.desc ?? undefined}
          />
        ) : (
          <Box
            width={width}
            height={photoHeight}
            sx={{ backgroundColor: 'lightgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <NoPhotographyIcon fontSize="large" />
          </Box>
        )}
      </CardActionArea>
      <CardHeader
        title={property.name}
        titleTypographyProps={{
          noWrap: true,
          textOverflow: 'ellipsis',
        }}
        sx={{
          py: 1,
          '& .MuiCardHeader-content': {
            display: 'block',
            overflow: 'hidden',
          },
        }}
        action={
          <>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
            {!property.deletedAt && (
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </>
        }
      />
    </Card>
  );
}
