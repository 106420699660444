import { useContext } from 'react';
import { AuthContext, AuthContextData, AuthUser } from './auth-context-provider';

/**
 * Hook to access the auth context data
 * @param T The type of the user data
 * @returns {AuthContextData} The auth context data
 */
export function useAuth<T extends AuthUser = AuthUser>(): AuthContextData<T> {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context as AuthContextData<T>;
}
