import React, { ChangeEvent, useMemo } from 'react';
import { ContractFragmentFragment, RentPeriod } from '../../../_generated/graphql';
import { FormControl, FormLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FormInput } from '../../../components/form-input';

interface InputRentSegmentProps {
  contract: ContractFragmentFragment;
  onUpdate: (data: Partial<InputRentSegmentFields>) => void;
}

interface InputRentSegmentFields {
  rent: number;
  deposit: number;
  rentPeriod: RentPeriod;
  rentFrequency: number;
  rentDueDay: number;
}

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function InputRentSegment(props: InputRentSegmentProps) {
  const { contract: c, onUpdate } = props;

  const dueRange = useMemo(() => range(-30, 30, 1), []);
  const freqRange = useMemo(() => range(1, 12, 1), []);

  const freqRangeLabel = useMemo(() => {
    const txt = c.rentPeriod === 'WEEK' ? ['týždeň', 'týždne', 'týždňov'] : ['mesiac', 'mesiace', 'mesiacov'];
    return freqRange.map((i) => (i === 1 ? `1 ${txt[0]}` : i < 5 ? `${i} ${txt[1]}` : `${i} ${txt[2]}`));
  }, [c.rentPeriod, freqRange]);

  const handleUpdate = (e: SelectChangeEvent | ChangeEvent) => {
    const t = e.target as HTMLInputElement;
    if (t.type === 'number') {
      onUpdate({ [t.name]: parseFloat(t.value) });
    } else {
      onUpdate({ [t.name]: t.value });
    }
  };

  return (
    <>
      <Grid xs={12} sm={6} md={6} lg={2}>
        <FormInput name="rent" label="Nájomné" type="number" value={c?.rent?.toString()} onChange={handleUpdate} />
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={2}>
        <FormInput name="deposit" label="Záloha" type="number" value={c?.deposit?.toString()} onChange={handleUpdate} />
      </Grid>

      <Grid xs={12} sm={4}>
        <FormControl fullWidth variant="standard">
          <FormLabel>Nájomné je</FormLabel>
          <Select<RentPeriod> name="rentPeriod" value={c?.rentPeriod ?? ''} onChange={handleUpdate}>
            <MenuItem value="MONTH">mesačné</MenuItem>
            <MenuItem value="WEEK">týždenné</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={4}>
        <FormControl fullWidth variant="standard">
          <FormLabel>Platí sa každé</FormLabel>
          <Select<string> name="rentFrequency" value={c?.rentFrequency?.toString() ?? ''} onChange={handleUpdate}>
            {freqRange.map((i, idx) => (
              <MenuItem key={i} value={i}>
                {freqRangeLabel[idx]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={4}>
        <FormControl fullWidth variant="standard">
          <FormLabel>Deň splatnosti</FormLabel>
          <Select name="rentDueDay" value={c?.rentDueDay?.toString() ?? ''} onChange={handleUpdate}>
            {dueRange.map((i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
