import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { FormInput } from '../../../components/form-input';
import { ServiceFragmentFragment, ServicePaymentType } from '../../../_generated/graphql';

interface AddServiceDialogProps {
  services: ServiceFragmentFragment[];
  onSubmit: (state: AddServiceDialogState) => void;
  onClose: () => void;
}

export interface AddServiceDialogState {
  serviceId: string;
  note?: string;
  paymentType: ServicePaymentType;
  unit: string;
  price: number;
  cost: number;
}

export function AddServiceDialog(props: AddServiceDialogProps) {
  const { services, onClose } = props;
  const [state, setState] = useState<AddServiceDialogState>({
    serviceId: '',
    paymentType: 'IN_RENT',
    unit: '',
    price: 0,
    cost: 0,
  });
  const { onSubmit } = props;

  const handleServiceChange = useCallback(
    (e: SelectChangeEvent) => {
      const service = services.find((s) => s.id === e.target.value);
      if (!service) {
        return;
      }
      setState((s) => ({
        ...s,
        serviceId: service.id,
        unit: service.unit,
        price: service.price,
        cost: service.cost,
      }));
    },
    [services],
  );

  return (
    <Dialog open sx={{ minWidth: 350 }}>
      <DialogTitle>Služba</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="standard">
          <FormLabel>Služba</FormLabel>
          <Select<string> name="service" value={state.serviceId ?? ''} onChange={handleServiceChange}>
            {services.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {state.serviceId && (
          <>
            <FormInput
              label="Poznámka"
              value={state.note ?? ''}
              onChange={(e) => setState((s) => ({ ...s, note: (e.target as HTMLInputElement).value ?? '' }))}
            />
            <FormControl>
              <FormLabel>Úhrada za službu</FormLabel>
              <RadioGroup
                value={state.paymentType}
                name="radio-buttons-group"
                onChange={(e) => setState((s) => ({ ...s, paymentType: e.target.value as ServicePaymentType }))}
              >
                <FormControlLabel value="IN_RENT" control={<Radio />} label="v nájomnom" />
                <FormControlLabel value="FIXED" control={<Radio />} label="fixnou sumou" />
                <FormControlLabel value="VARIABLE" control={<Radio />} label="podľa spotreby" />
                <FormControlLabel value="ADVANCE" control={<Radio />} label="zálohová platba" />
                <FormControlLabel value="EXTERNAL" control={<Radio />} label="priamo dodávateľovi" />
              </RadioGroup>
            </FormControl>
            {(state.paymentType === 'VARIABLE' || state.paymentType === 'FIXED') && (
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} useFlexGap flexWrap="wrap">
                <FormInput
                  label="Jadnotka"
                  type="string"
                  value={state.unit}
                  onChange={(e) => setState((s) => ({ ...s, unit: (e.target as HTMLInputElement).value ?? '' }))}
                />
                <FormInput
                  label="Cena za jednotku"
                  type="number"
                  value={state.price.toString()}
                  onChange={(e) =>
                    setState((s) => ({ ...s, price: Number((e.target as HTMLInputElement).value) ?? 0 }))
                  }
                />
              </Stack>
            )}
            <FormInput
              label="Náklady na jednotku"
              type="number"
              value={state.cost.toString()}
              onChange={(e) => setState((s) => ({ ...s, cost: Number((e.target as HTMLInputElement).value) ?? 0 }))}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(state)}>Pridať</Button>
        <Button onClick={() => onClose()}>Zatvoriť</Button>
      </DialogActions>
    </Dialog>
  );
}
