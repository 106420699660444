import React from 'react';
import { Button, ButtonProps, CircularProgress, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from './visually-hidden-input';

export type UploadButtonProps = ButtonProps & {
  label: string;
  inProgress: boolean;
  onUpload: (files: FileList) => void;
};

export function UploadButton({ label, inProgress, onUpload, ...buttonProps }: UploadButtonProps) {
  return inProgress ? (
    <CircularProgress />
  ) : (
    <Button variant="outlined" startIcon={<CloudUploadIcon />} component="label" {...buttonProps}>
      <VisuallyHiddenInput
        type="file"
        multiple
        onChange={(e) => {
          if (e.target.files) {
            onUpload(e.target.files);
          }
        }}
      />
      <Typography variant="h5">{label}</Typography>
    </Button>
  );
}
