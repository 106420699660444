import { FormControl, FormHelperText, FormLabel, Input } from '@mui/material';
import React from 'react';
interface FormInputProps {
  name?: string;
  label: string;
  value?: string | null;
  type?: string;
  required?: boolean;
  helperText?: string;
  onChange: (e: React.ChangeEvent) => void;
}

export function FormInput(props: FormInputProps) {
  return (
    <FormControl required={props.required} fullWidth>
      <FormLabel>{props.label}</FormLabel>
      <Input name={props.name} type={props.type} defaultValue={props.value ?? undefined} onChange={props.onChange} />
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}
