import React from 'react';
import { ContractFragmentFragment } from '../_generated/graphql';
import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { LabelAndValue } from './label-and-value';

interface ContractCardProps {
  contract: ContractFragmentFragment;
  onClick: (contract: ContractFragmentFragment) => void;
}

export function ContractCard(props: ContractCardProps) {
  const { contract, onClick } = props;

  const photo = contract.mainProperty?.coverPhoto;
  return (
    <Card sx={{ width: 400 }} onClick={() => onClick(contract)}>
      <CardMedia sx={{ height: 140 }} image={photo?.url} title={photo?.desc ?? undefined} />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {contract.mainProperty?.name}
        </Typography>

        <Typography variant="body2">{contract.signingDate ? contract.tenantName : contract.contactName}</Typography>
        <Stack direction="row" spacing={2}>
          <div>
            <LabelAndValue label="Nájomné" value={contract.rent ?? 'neurčené'} />
            <LabelAndValue label="Záloha" value={contract.deposit ?? 'neurčen8'} />
            <LabelAndValue label="Začiatok" value={contract.startDate ?? 'neurčený'} />
            <LabelAndValue label="Koniec" value={contract.endDate ?? 'neurčený'} />
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
}
