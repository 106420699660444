import React, { useCallback } from 'react';
import { AuthContextProvider, AuthedApolloProvider } from '@snuglyrent/auth-react';
import { AppRouter } from './routes';
import { useState } from 'react';
import { getAppConfig } from './app-config';
import { IUserData } from './types';
import { ThemeProvider } from './theme';
import { AlertProvider, Alerts } from '@snuglyrent/react-alert';

function getToken() {
  return localStorage.getItem('token');
}

export function App() {
  const [token, setToken] = useState(getToken());
  const [config] = useState(getAppConfig());

  const storeToken = useCallback(
    (token: string | null, staySigned?: boolean) => {
      if (!token) {
        localStorage.removeItem('token');
        localStorage.removeItem('staySigned');
      } else {
        localStorage.setItem('token', token);
        if (staySigned) {
          localStorage.setItem('staySigned', 'true');
        } else {
          localStorage.removeItem('staySigned');
        }
      }
      setToken(token);
    },
    [setToken],
  );

  return (
    <AuthContextProvider<IUserData>
      authEndpoint={config.apiUrl + '/auth'}
      token={token}
      onLogin={(u, p?: boolean) => storeToken(u.token, p)}
      onError={(e) => storeToken(null, false)}
      onLogout={() => storeToken(null, false)}
    >
      <AuthedApolloProvider apiUri={`${config.apiUrl}/graphql`} apiToken={token}>
        <ThemeProvider>
          <AlertProvider>
            <AppRouter />
            <Alerts width={500} verticalAlign="bottom" horizontalAlign="center" />
          </AlertProvider>
        </ThemeProvider>
      </AuthedApolloProvider>
    </AuthContextProvider>
  );
}

export default App;
