import * as React from 'react';

import GlobalStyles from '@mui/material/GlobalStyles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { formLabelClasses } from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useAuth } from '@snuglyrent/auth-react';

import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import GoogleIcon from '../../components/google-icon';
import { useNavigate } from 'react-router-dom';
import { SignInForm } from './components/signin-form';
import { appPaths } from '../../app-paths';

export function SignInPage() {
  const { login } = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: 'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: 'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <IconButton color="primary" size="small">
                <BadgeRoundedIcon />
              </IconButton>
              <Typography variant="h6">Company logo</Typography>
            </Box>
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography variant="h4">Sign in</Typography>
                <Typography variant="body1">
                  New to company?{' '}
                  <Link href={appPaths.signup()} underline="none">
                    Sign up!
                  </Link>
                </Typography>
              </Stack>
              <Button fullWidth variant="contained" startIcon={<GoogleIcon />}>
                Continue with Google
              </Button>
            </Stack>
            <Divider>or</Divider>
            <Stack gap={4} sx={{ mt: 2 }}>
              <SignInForm
                onSubmit={(username: string, password: string, persist: boolean) => {
                  login(username, password, persist).then(() => {
                    navigate(appPaths.dashboard());
                  });
                }}
              />
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography variant="body2" textAlign="center">
              © Your company {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
        }}
      />
    </>
  );
}
