import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ContractedServiceInput,
  UpdateContractInput,
  useAddPropertyToContractMutation,
  useAddServiceToContractMutation,
  useDeleteFileFromContractMutation,
  useGetContractLazyQuery,
  useGetPropertiesQuery,
  useRemovePropertyFromContractMutation,
  useRemoveServiceFromContractMutation,
  useSignContractMutation,
  useUpdateContractMutation,
} from '../../_generated/graphql';

import { useAlert } from '@snuglyrent/react-alert';
import { Button, Typography, Input } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Grid from '@mui/material/Unstable_Grid2';

import { BasePage } from '../../components/base-page';
import { AddServiceDialog, AddServiceDialogState } from './components/add-service-dialog';
import { InputContactSegment } from './components/input-contact-segment';
import { ContractFormData } from './components/types';
import { DelayedWriter } from '../../lib/delayed-writer';
import { PropertyList } from './components/property-list';
import { FormInput } from '../../components/form-input';
import { InputRentSegment } from './components/input-rent-segment';

import { ContractServices } from './components/contract-services';
import { AddPropertyDialog } from './components/add-property-dialog';
import { FileRefList } from '../../components/fileref-list';
import { ContractFileUploader } from './components/contract-file-uploader';
import { ContractPhotoCapture } from './components/contract-photo-capture';

export function ContractDetailPage() {
  const { id } = useParams();
  const { show } = useAlert();
  const [showAddServiceDialog, setShowAddServiceDialog] = useState(false);
  const [showAddPropertyDialog, setShowAddPropertyDialog] = useState(false);

  const [fetchContract, { data, loading, error, refetch }] = useGetContractLazyQuery();
  const [updateContract] = useUpdateContractMutation({
    onError: (e) => show.error('Nepodarilo sa uložiť zmeny', e.message),
  });

  const [signContract] = useSignContractMutation({
    onError: (e) => show.error('Nepodarilo sa označiť prenájom ako podpísaný.'),
  });
  const [addProperty] = useAddPropertyToContractMutation({
    onError: (e) => show.error('Nepodarilo sa pridať nehnuteľnosť.', e.message),
  });
  const [removeProperty] = useRemovePropertyFromContractMutation({
    onError: (e) => show.error('Nepodarilo sa odstrániť nehnuteľnosť.', e.message),
  });
  const [addService] = useAddServiceToContractMutation();
  const [removeService] = useRemoveServiceFromContractMutation();
  const [deleteFile] = useDeleteFileFromContractMutation();

  const handleUpdate = useCallback(
    (input: UpdateContractInput) => {
      if (!id) {
        return;
      }
      updateContract({ variables: { id, input } });
    },
    [id, updateContract],
  );

  const [delayedWriter] = useState(new DelayedWriter<ContractFormData>(handleUpdate));

  const { data: propsData } = useGetPropertiesQuery();

  useEffect(() => {
    if (id) {
      fetchContract({ variables: { id } });
    }
  }, [id, fetchContract]);

  const handleInputUpdate = useCallback(
    (e: React.ChangeEvent | null) => {
      if (!e) {
        return;
      }
      const el = e.target as HTMLInputElement;
      const name = el.name;
      let value: string | number | boolean = el.value;
      if (el.type === 'number') {
        value = parseFloat(value);
      }
      if (el.type === 'checkbox') {
        value = el.checked;
      }
      delayedWriter.write({ [name]: value });
    },
    [delayedWriter],
  );

  const handleAddService = useCallback(
    async (fields: AddServiceDialogState) => {
      if (!id) {
        return;
      }
      const name = data?.getContract?.mainProperty?.services.find((s) => s.id === fields.serviceId)?.name ?? '';
      const input: ContractedServiceInput = { name, ...fields };
      await addService({ variables: { id, input } });
      setShowAddServiceDialog(false);
    },
    [addService, data?.getContract, id],
  );

  const c = data?.getContract;
  const contractProperties = c?.properties ?? [];
  const accountProperties = propsData?.getProperties ?? [];

  if (error) {
    return <div>Error loading contract</div>;
  }

  if (loading || !id || !c) {
    return <div>Loading...</div>;
  }

  return (
    <BasePage>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Typography variant="h1">Zmluva</Typography>

        <Grid xs={12}>
          <Typography variant="h3">Kontakt</Typography>
        </Grid>
        <InputContactSegment contract={c} onUpdate={handleInputUpdate} />

        <Grid xs={12}>
          <Typography variant="h3">Poznámky</Typography>
          <Input name="note" multiline defaultValue={c?.note} onChange={handleInputUpdate} fullWidth rows={7} />
        </Grid>

        <Grid xs={12}>
          <Grid xs={12}>
            <Typography variant="h3">Prenajímané nehnuteľnosti</Typography>
          </Grid>
          <PropertyList
            properties={contractProperties}
            onRemove={(propertyId) => removeProperty({ variables: { id, propertyId } })}
          />
          <Button
            onClick={() => setShowAddPropertyDialog(true)}
            disabled={accountProperties.length === contractProperties.length}
          >
            Pridaj nehnuteľnosť
          </Button>
        </Grid>

        <Grid container spacing={2} xs={12}>
          <Grid xs={12} sm={6} lg={3}>
            <FormInput
              name="startDate"
              label="Začiatok nájmu"
              type="date"
              value={c?.startDate}
              onChange={handleInputUpdate}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={3}>
            <FormInput
              name="endDate"
              label="Koniec nájmu"
              type="date"
              value={c?.endDate}
              onChange={handleInputUpdate}
            />
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Typography variant="h3">Nájomné</Typography>
        </Grid>
        <InputRentSegment contract={c} onUpdate={(data) => delayedWriter.write(data)} />

        <Grid xs={12}>
          <Typography variant="h3">Služby a energie</Typography>
        </Grid>
        <ContractServices
          services={c.services ?? []}
          onRemove={(sid) => removeService({ variables: { id, serviceId: sid } })}
        />
        <Grid sm={12}>
          <Button onClick={() => setShowAddServiceDialog(true)}>Pridaj službu</Button>
        </Grid>

        <Grid xs={12}>
          <Typography variant="h3">Súbory</Typography>
          <FileRefList files={c.files} onDelete={(f) => deleteFile({ variables: { id: c.id, fileId: f.id } })} />
          <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
            <ContractFileUploader
              contractId={c.id}
              type="cd"
              onProgress={(e) => console.log(e?.loaded, '/', e?.total)}
              onUploadComplete={() => refetch()}
            />
            <Typography variant="h5">Pridaj dokument</Typography>
          </Button>
          <Button variant="contained" component="label" startIcon={<PhotoCameraIcon />}>
            <ContractPhotoCapture
              contractId={c.id}
              type="cd"
              onProgress={(e) => console.log(e?.loaded, '/', e?.total)}
              onUploadComplete={() => refetch()}
            />
          </Button>
        </Grid>
      </Grid>

      {showAddServiceDialog && (
        <AddServiceDialog
          services={c.mainProperty?.services.filter((s) => !c.services.some((ss) => ss.id === s.id)) ?? []}
          onSubmit={handleAddService}
          onClose={() => setShowAddServiceDialog(false)}
        />
      )}
      {showAddPropertyDialog && (
        <AddPropertyDialog
          selected={contractProperties}
          properties={accountProperties}
          onSubmit={async (propertyId) => {
            await addProperty({ variables: { id, propertyId } });
            setShowAddPropertyDialog(false);
          }}
          onClose={() => setShowAddPropertyDialog(false)}
        />
      )}
    </BasePage>
  );
}
