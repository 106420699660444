import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import React, { FormEvent } from 'react';

interface SignInFormProps {
  onSubmit: (username: string, password: string, persist: boolean) => void;
}

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export function SignInForm(props: SignInFormProps) {
  return (
    <form
      onSubmit={(event: FormEvent<SignInFormElement>) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
          username: formElements.username.value,
          password: formElements.password.value,
          persistent: formElements.persistent.checked,
        };
        props.onSubmit(data.username, data.password, data.persistent);
      }}
    >
      <FormControl required>
        <FormLabel>Username/E-mail</FormLabel>
        <Input name="username" />
      </FormControl>
      <FormControl required>
        <FormLabel>Password</FormLabel>
        <Input type="password" name="password" />
      </FormControl>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControlLabel control={<Checkbox size="small" name="persistent" />} label="Stay signed-in" />
          <Link href="#replace-with-a-link">Forgot your password?</Link>
        </Box>
        <Button type="submit" fullWidth variant="contained">
          Sign in
        </Button>
      </Stack>
    </form>
  );
}
