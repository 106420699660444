import React from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { ContractedServiceFragmentFragment } from '../../../_generated/graphql';

interface ContractServicesProps {
  services: ContractedServiceFragmentFragment[];
  onRemove: (serviceId: string) => void;
}

export function ContractServices(props: ContractServicesProps) {
  const { services } = props;
  return services.map((s, lineNo) => (
    <>
      <Grid xs={12} md={6} sx={{ backgroundColor: 'lightskyblue' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton aria-label="delete" onClick={() => props.onRemove(s.id)} size="small">
            <RemoveCircleOutlineIcon color="error" />
          </IconButton>
          <Stack direction="column" spacing={1}>
            <Typography>{s.name}</Typography>
            {s.note && <Typography variant="body2">{s.note}</Typography>}
          </Stack>
        </Stack>
      </Grid>
      <Grid xs={4} md={2}>
        <Typography>{s.paymentType}</Typography>
      </Grid>
      <Grid xs={4} md={2}>
        <Typography>Cena</Typography>
        <Typography>
          {s.price}/{s.unit}
        </Typography>
      </Grid>

      <Grid xs={4} md={2}>
        <Typography>Náklad</Typography>
        <Typography>{s.cost}</Typography>
      </Grid>
    </>
  ));
}
