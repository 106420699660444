import React, { useCallback } from 'react';

import {
  FileRefDocType,
  useAddFileToContractMutation,
  useConfirmFileUploadedToContractMutation,
} from '../../../_generated/graphql';
import { uploadXHR } from '../../../lib/upload-xhr';
import { VisuallyHiddenInput } from '../../../components/visually-hidden-input';

interface ContractFileUploaderProps {
  contractId: string;
  type: FileRefDocType;
  onUploadComplete?: () => void;
  onProgress?: (e?: ProgressEvent<XMLHttpRequestEventTarget>) => void;
}

export function ContractFileUploader(props: ContractFileUploaderProps) {
  const [addFile] = useAddFileToContractMutation();
  const [confirmUpload] = useConfirmFileUploadedToContractMutation();
  const { contractId, onUploadComplete, type } = props;

  const handleFileUpload = useCallback(
    async (files: FileList | null) => {
      if (!files) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        // get upload url for each file
        addFile({
          variables: { id: contractId, type, input: { filename: f.name, mimeType: f.type, size: f.size } },
          onCompleted: async (data) => {
            // upload file to s3
            const uploadStatus = await uploadXHR(f, data.addFileToContract.uploadUrl, {
              onProgress: props.onProgress,
            });
            if (uploadStatus === 200) {
              // confirm upload
              confirmUpload({
                variables: { id: contractId, fileId: data.addFileToContract.fileId, size: f.size },
                onCompleted: () => onUploadComplete && onUploadComplete(),
              });
            }

            //TODO status/result notification
          },
        });
      }
    },
    [addFile, contractId, type, props.onProgress, confirmUpload, onUploadComplete],
  );

  return <VisuallyHiddenInput type="file" multiple onChange={(e) => handleFileUpload(e.target.files)} />;
}
