import * as React from 'react';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

export function SplashPage() {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      <CircularProgress sx={{ color: theme.palette.primary.contrastText }} />
      <Typography sx={{ color: theme.palette.primary.contrastText }} variant="h4">
        SnuglyRent
      </Typography>
    </Stack>
  );
}
