import React from 'react';

import { AlertVariant } from './provider';

export interface AlertComponentProps {
  message: string;
  details?: string;
  variant?: AlertVariant;
  onClose: () => void;
}

export function Alert(props: AlertComponentProps) {
  const { message, details, variant = 'info', onClose } = props;
  const color = variant === 'info' ? 'rgba(240, 230, 140, 0.9)' : 'rgba(255, 69, 0, 0.9)';
  return (
    <div style={{ width: '100%', height: 60, backgroundColor: color, padding: 5, marginTop: 10 }}>
      <button style={{ float: 'right', background: 'none', border: 'none' }} onClick={onClose}>
        X
      </button>
      <p>{message}</p>
      {details && <p>{details}</p>}
    </div>
  );
}
