import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { DashboardPage } from './pages/dashboard';
import { ContractDetailPage } from './pages/contract-detail';
import { PropertyDetailPage } from './pages/property-detail';
import { useAuth } from '@snuglyrent/auth-react';
import { SignInPage } from './pages/signin';
import { SignUpPage } from './pages/signup';
import { appPaths } from './app-paths';
import { PropertyListPage } from './pages/property-list';
import { ContractListPage } from './pages/contract-list';
import { SplashPage } from './pages/splash';

export function AppRouter() {
  const { isAuthenticated, isLoading } = useAuth();

  return isLoading ? (
    <SplashPage />
  ) : (
    <Routes>
      <Route path={appPaths.dashboard()} element={isAuthenticated ? <DashboardPage /> : <SignInPage />} />
      <Route path={appPaths.propertyList()} element={isAuthenticated ? <PropertyListPage /> : <SignInPage />} />
      <Route path={appPaths.property()} element={isAuthenticated ? <PropertyDetailPage /> : <SignInPage />} />
      <Route path={appPaths.contractList()} element={isAuthenticated ? <ContractListPage /> : <SignInPage />} />
      <Route path={appPaths.contract()} element={isAuthenticated ? <ContractDetailPage /> : <SignInPage />} />
      <Route path={appPaths.signin()} element={<SignInPage />} />
      <Route path={appPaths.signup()} element={<SignUpPage />} />

      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
}
