import React, { useState } from 'react';
import { AppBar, Box, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { appPaths } from '../app-paths';

interface TopBarProps {
  onLogout: () => void;
  onMobileNavigationToggle: () => void;
}

export function TopBar(props: TopBarProps) {
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setUserMenuAnchor(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.secondary.main,
      })}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="primary"
          onClick={props.onMobileNavigationToggle}
          sx={{ mr: 1, display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, padding: 0, lineHeight: 0, textAlign: { sm: 'center', lg: 'left' } }}>
          <Link href={appPaths.dashboard()}>
            <img src="./header.png" className="headerLogo" />
          </Link>
        </Box>
        <IconButton size="large" color="primary" onClick={(event) => setUserMenuAnchor(event.currentTarget)}>
          <AccountCircle />
        </IconButton>
        <Menu anchorEl={userMenuAnchor} keepMounted open={Boolean(userMenuAnchor)} onClose={handleMenuClose}>
          <MenuItem
            onClick={() => {
              props.onLogout();
              handleMenuClose();
            }}
          >
            Odhlásiť sa
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
