/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Javascript Date object */
  DateTime: { input: string; output: string };
  /** Scalar representing email. */
  Email: { input: any; output: any };
  /** Scalar representing an error code in mutation response payload. */
  ErrorCode: { input: any; output: any };
  /** Scalar representing an field in mutation response payload. */
  Field: { input: any; output: any };
  /** Scalar representing phone number. */
  PhoneNumber: { input: any; output: any };
};

export type Account = INode & {
  __typename?: 'Account';
  canManage: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['ID']['output'];
  services: Array<Service>;
};

export type AccountPayload = IMutationPayload & {
  __typename?: 'AccountPayload';
  account?: Maybe<Account>;
  errors?: Maybe<Array<RequestError>>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Contract = INode & {
  __typename?: 'Contract';
  accountId: Scalars['ID']['output'];
  /** Whether the user can manage this contract */
  canManage: Scalars['Boolean']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deposit?: Maybe<Scalars['Float']['output']>;
  /** Tenancy end date */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  files: Array<FileRef>;
  id: Scalars['ID']['output'];
  mainProperty?: Maybe<Property>;
  note: Scalars['String']['output'];
  properties: Array<Property>;
  /** List of properties that are part of this contract */
  propertyIds: Array<Scalars['ID']['output']>;
  rent?: Maybe<Scalars['Float']['output']>;
  /** Day of the month when rent is due. If negative, then it's the day of the previous month */
  rentDueDay?: Maybe<Scalars['Int']['output']>;
  rentFrequency?: Maybe<Scalars['Int']['output']>;
  rentPeriod?: Maybe<RentPeriod>;
  services: Array<ContractedService>;
  /** Contract signing date */
  signingDate?: Maybe<Scalars['DateTime']['output']>;
  /** Tenancy start date */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  tenantAddress?: Maybe<Address>;
  tenantEmail?: Maybe<Scalars['String']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
  tenantPhone?: Maybe<Scalars['String']['output']>;
};

export type ContractPayload = IMutationPayload & {
  __typename?: 'ContractPayload';
  contract?: Maybe<Contract>;
  errors?: Maybe<Array<RequestError>>;
};

export type ContractedService = INode & {
  __typename?: 'ContractedService';
  cost: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  paymentType: ServicePaymentType;
  price: Scalars['Float']['output'];
  serviceId: Scalars['ID']['output'];
  unit: Scalars['String']['output'];
};

export type ContractedServiceInput = {
  cost: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  paymentType: ServicePaymentType;
  price: Scalars['Float']['input'];
  serviceId: Scalars['ID']['input'];
  unit: Scalars['String']['input'];
};

export type CreatePropertyInput = {
  name: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['Email']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  username: Scalars['String']['input'];
};

export type FileRef = {
  __typename?: 'FileRef';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  size: Scalars['Int']['output'];
  uploadedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type FileRefDocType =
  /** contract doc */
  | 'cd'
  /** contract doc move in */
  | 'cdmi'
  /** contract doc move out */
  | 'cdmo'
  /** property doc private */
  | 'pdp'
  /** property doc tenant */
  | 'pdt'
  /** property photo */
  | 'pp';

export type FileRefUploadInput = {
  filename: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type FileRefUploadPayload = {
  __typename?: 'FileRefUploadPayload';
  fileId: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

/** Represents a payload of data that can return validation errors */
export type IMutationPayload = {
  errors?: Maybe<Array<RequestError>>;
};

/** Represents objects with globally unique id */
export type INode = {
  id: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Placeholder type as graphQL does not allow to define empty types */
  _?: Maybe<Scalars['Boolean']['output']>;
  addFileToContract: FileRefUploadPayload;
  addFileToProperty: FileRefUploadPayload;
  addPropertyPhoto: FileRefUploadPayload;
  addPropertyToContract: ContractPayload;
  addServiceToContract: ContractPayload;
  changePassword: UserPayload;
  confirmFileUploadedToContract: FileRef;
  confirmFileUploadedToProperty: FileRef;
  confirmPropertyPhotoUpload: PropertyPayload;
  createContract: ContractPayload;
  createProperty: PropertyPayload;
  createUser: UserPayload;
  deleteContract: ContractPayload;
  deleteFileFromContract: FileRef;
  deleteFileFromProperty: FileRef;
  deleteProperty: PropertyPayload;
  deletePropertyPhoto: PropertyPayload;
  removePropertyFromContract: ContractPayload;
  removeServiceFromContract: ContractPayload;
  setPropertyCoverPhoto: PropertyPayload;
  signContract: ContractPayload;
  updateAccount: AccountPayload;
  updateContract: ContractPayload;
  updateProperty: PropertyPayload;
  updatePropertyPhotoCaption: PropertyPhotoPayload;
  updateUser: UserPayload;
};

export type MutationAddFileToContractArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<FileRefUploadInput>;
  type: FileRefDocType;
};

export type MutationAddFileToPropertyArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<FileRefUploadInput>;
  type: FileRefDocType;
};

export type MutationAddPropertyPhotoArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<FileRefUploadInput>;
};

export type MutationAddPropertyToContractArgs = {
  id: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type MutationAddServiceToContractArgs = {
  id: Scalars['ID']['input'];
  input: ContractedServiceInput;
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationConfirmFileUploadedToContractArgs = {
  fileId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  size: Scalars['Int']['input'];
};

export type MutationConfirmFileUploadedToPropertyArgs = {
  fileId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  size: Scalars['Int']['input'];
};

export type MutationConfirmPropertyPhotoUploadArgs = {
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
  size: Scalars['Int']['input'];
};

export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteContractArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFileFromContractArgs = {
  fileId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationDeleteFileFromPropertyArgs = {
  fileId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationDeletePropertyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePropertyPhotoArgs = {
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
};

export type MutationRemovePropertyFromContractArgs = {
  id: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export type MutationRemoveServiceFromContractArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};

export type MutationSetPropertyCoverPhotoArgs = {
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
};

export type MutationSignContractArgs = {
  id: Scalars['ID']['input'];
  signingDate: Scalars['DateTime']['input'];
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateContractArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContractInput;
};

export type MutationUpdatePropertyArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePropertyInput;
};

export type MutationUpdatePropertyPhotoCaptionArgs = {
  caption?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Property = INode & {
  __typename?: 'Property';
  accountId: Scalars['ID']['output'];
  address: Scalars['String']['output'];
  /** Whether the current account can manage this property. */
  canManage: Scalars['Boolean']['output'];
  coverPhoto?: Maybe<PropertyPhoto>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** The files that are shared with tenants. e.g. inventory, equipment manuals, floorplan etc. */
  filesForTenant: Array<FileRef>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  photos: Array<PropertyPhoto>;
  /** The documents/files associated with this property, but not shared with tenants. e.g. insurance contract, services contracts etc. */
  propertyFiles: Array<FileRef>;
  /** The total cost of rent per month. */
  rent: Scalars['Float']['output'];
  /** The total estimated fees for services and energies per month. Used for advance payments. */
  serviceFees: Scalars['Float']['output'];
  /** The services associated with this property. e.g. gas, electricity, water etc. */
  services: Array<Service>;
};

export type PropertyPayload = IMutationPayload & {
  __typename?: 'PropertyPayload';
  errors?: Maybe<Array<RequestError>>;
  property?: Maybe<Property>;
};

export type PropertyPhoto = {
  __typename?: 'PropertyPhoto';
  desc?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  uploadedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type PropertyPhotoPayload = IMutationPayload & {
  __typename?: 'PropertyPhotoPayload';
  errors?: Maybe<Array<RequestError>>;
  photo?: Maybe<PropertyPhoto>;
};

export type Query = {
  __typename?: 'Query';
  /** Placeholder type as graphQL does not allow to define empty types */
  _?: Maybe<Scalars['Boolean']['output']>;
  getAccountById: Account;
  getAccounts: Array<Account>;
  getContract: Contract;
  getContracts: Array<Contract>;
  /** Get all properties for the current account */
  getProperties: Array<Property>;
  /** Get a property by id. If property is not found, or is not accessible by the current account, an error is thrown. */
  getProperty: Property;
  getUserById: UserPayload;
  getUserByUsername: UserPayload;
  getUsers: Array<User>;
};

export type QueryGetAccountByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetContractArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetPropertyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetUserByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetUserByUsernameArgs = {
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RentPeriod = 'MONTH' | 'WEEK';

/** Represents request error */
export type RequestError = {
  __typename?: 'RequestError';
  /** An error code for clients to match on. */
  code: Scalars['ErrorCode']['output'];
  /** Indicates which field caused the error */
  field?: Maybe<Scalars['Field']['output']>;
};

export type Service = {
  __typename?: 'Service';
  accountId: Scalars['ID']['output'];
  cost: Scalars['Float']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  propertyId: Scalars['ID']['output'];
  unit: Scalars['String']['output'];
};

export type ServicePaymentType = 'ADVANCE' | 'EXTERNAL' | 'FIXED' | 'IN_RENT' | 'VARIABLE';

export type UpdateAccountInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractInput = {
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  deposit?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  rent?: InputMaybe<Scalars['Float']['input']>;
  rentDueDay?: InputMaybe<Scalars['Int']['input']>;
  rentFrequency?: InputMaybe<Scalars['Int']['input']>;
  rentPeriod?: InputMaybe<RentPeriod>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  tenantAddress?: InputMaybe<AddressInput>;
  tenantEmail?: InputMaybe<Scalars['String']['input']>;
  tenantName?: InputMaybe<Scalars['String']['input']>;
  tenantPhone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePropertyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rent?: InputMaybe<Scalars['Float']['input']>;
  serviceFees?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
};

export type User = INode & {
  __typename?: 'User';
  account: Account;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  username: Scalars['String']['output'];
};

export type UserPayload = IMutationPayload & {
  __typename?: 'UserPayload';
  errors?: Maybe<Array<RequestError>>;
  user?: Maybe<User>;
};

export type ContractPayloadFragmentFragment = {
  __typename?: 'ContractPayload';
  contract?: {
    __typename?: 'Contract';
    deposit?: number | null;
    rent?: number | null;
    rentPeriod?: RentPeriod | null;
    rentFrequency?: number | null;
    rentDueDay?: number | null;
    deletedAt?: string | null;
    createdAt: string;
    id: string;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhone?: string | null;
    note: string;
    tenantName?: string | null;
    tenantEmail?: string | null;
    tenantPhone?: string | null;
    signingDate?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    canManage: boolean;
    tenantAddress?: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null;
      city: string;
      state?: string | null;
      zip: string;
      country?: string | null;
    } | null;
    mainProperty?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    properties: Array<{
      __typename?: 'Property';
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    }>;
    services: Array<{
      __typename?: 'ContractedService';
      id: string;
      serviceId: string;
      name: string;
      note?: string | null;
      paymentType: ServicePaymentType;
      unit: string;
      price: number;
      cost: number;
    }>;
    files: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
  } | null;
  errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
};

export type CreateContractMutationVariables = Exact<{ [key: string]: never }>;

export type CreateContractMutation = {
  __typename?: 'Mutation';
  createContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type UpdateContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateContractInput;
}>;

export type UpdateContractMutation = {
  __typename?: 'Mutation';
  updateContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type DeleteContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteContractMutation = {
  __typename?: 'Mutation';
  deleteContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type SignContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  signingDate: Scalars['DateTime']['input'];
}>;

export type SignContractMutation = {
  __typename?: 'Mutation';
  signContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type AddPropertyToContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type AddPropertyToContractMutation = {
  __typename?: 'Mutation';
  addPropertyToContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type RemovePropertyFromContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
}>;

export type RemovePropertyFromContractMutation = {
  __typename?: 'Mutation';
  removePropertyFromContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type AddServiceToContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ContractedServiceInput;
}>;

export type AddServiceToContractMutation = {
  __typename?: 'Mutation';
  addServiceToContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type RemoveServiceFromContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
}>;

export type RemoveServiceFromContractMutation = {
  __typename?: 'Mutation';
  removeServiceFromContract: {
    __typename?: 'ContractPayload';
    contract?: {
      __typename?: 'Contract';
      deposit?: number | null;
      rent?: number | null;
      rentPeriod?: RentPeriod | null;
      rentFrequency?: number | null;
      rentDueDay?: number | null;
      deletedAt?: string | null;
      createdAt: string;
      id: string;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      note: string;
      tenantName?: string | null;
      tenantEmail?: string | null;
      tenantPhone?: string | null;
      signingDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      canManage: boolean;
      tenantAddress?: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        country?: string | null;
      } | null;
      mainProperty?: {
        __typename?: 'Property';
        description: string;
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        photos: Array<{
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        }>;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
        propertyFiles: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        filesForTenant: Array<{
          __typename?: 'FileRef';
          id: string;
          name?: string | null;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
          deletedAt?: string | null;
        }>;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      } | null;
      properties: Array<{
        __typename?: 'Property';
        address: string;
        rent: number;
        serviceFees: number;
        id: string;
        accountId: string;
        name: string;
        deletedAt?: string | null;
        canManage: boolean;
        coverPhoto?: {
          __typename?: 'PropertyPhoto';
          id: string;
          desc?: string | null;
          url: string;
          mimeType: string;
          size: number;
          uploadedAt?: string | null;
        } | null;
      }>;
      services: Array<{
        __typename?: 'ContractedService';
        id: string;
        serviceId: string;
        name: string;
        note?: string | null;
        paymentType: ServicePaymentType;
        unit: string;
        price: number;
        cost: number;
      }>;
      files: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type AddFileToContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  type: FileRefDocType;
  input: FileRefUploadInput;
}>;

export type AddFileToContractMutation = {
  __typename?: 'Mutation';
  addFileToContract: { __typename?: 'FileRefUploadPayload'; uploadUrl: string; fileId: string };
};

export type ConfirmFileUploadedToContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
  size: Scalars['Int']['input'];
}>;

export type ConfirmFileUploadedToContractMutation = {
  __typename?: 'Mutation';
  confirmFileUploadedToContract: {
    __typename?: 'FileRef';
    id: string;
    name?: string | null;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
    deletedAt?: string | null;
  };
};

export type DeleteFileFromContractMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
}>;

export type DeleteFileFromContractMutation = {
  __typename?: 'Mutation';
  deleteFileFromContract: {
    __typename?: 'FileRef';
    id: string;
    name?: string | null;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
    deletedAt?: string | null;
  };
};

export type GetContractsQueryVariables = Exact<{ [key: string]: never }>;

export type GetContractsQuery = {
  __typename?: 'Query';
  getContracts: Array<{
    __typename?: 'Contract';
    deposit?: number | null;
    rent?: number | null;
    rentPeriod?: RentPeriod | null;
    rentFrequency?: number | null;
    rentDueDay?: number | null;
    deletedAt?: string | null;
    createdAt: string;
    id: string;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhone?: string | null;
    note: string;
    tenantName?: string | null;
    tenantEmail?: string | null;
    tenantPhone?: string | null;
    signingDate?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    canManage: boolean;
    tenantAddress?: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null;
      city: string;
      state?: string | null;
      zip: string;
      country?: string | null;
    } | null;
    mainProperty?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    properties: Array<{
      __typename?: 'Property';
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    }>;
    services: Array<{
      __typename?: 'ContractedService';
      id: string;
      serviceId: string;
      name: string;
      note?: string | null;
      paymentType: ServicePaymentType;
      unit: string;
      price: number;
      cost: number;
    }>;
    files: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
  }>;
};

export type GetContractQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetContractQuery = {
  __typename?: 'Query';
  getContract: {
    __typename?: 'Contract';
    deposit?: number | null;
    rent?: number | null;
    rentPeriod?: RentPeriod | null;
    rentFrequency?: number | null;
    rentDueDay?: number | null;
    deletedAt?: string | null;
    createdAt: string;
    id: string;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhone?: string | null;
    note: string;
    tenantName?: string | null;
    tenantEmail?: string | null;
    tenantPhone?: string | null;
    signingDate?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    canManage: boolean;
    tenantAddress?: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null;
      city: string;
      state?: string | null;
      zip: string;
      country?: string | null;
    } | null;
    mainProperty?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    properties: Array<{
      __typename?: 'Property';
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    }>;
    services: Array<{
      __typename?: 'ContractedService';
      id: string;
      serviceId: string;
      name: string;
      note?: string | null;
      paymentType: ServicePaymentType;
      unit: string;
      price: number;
      cost: number;
    }>;
    files: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
  };
};

export type AccountFragmentFragment = {
  __typename?: 'Account';
  name: string;
  ownerId: string;
  disabledAt?: string | null;
  deletedAt?: string | null;
  canManage: boolean;
  services: Array<{
    __typename?: 'Service';
    id: string;
    name: string;
    description?: string | null;
    unit: string;
    price: number;
    cost: number;
    deletedAt?: string | null;
  }>;
};

export type AddressFragmentFragment = {
  __typename?: 'Address';
  line1: string;
  line2?: string | null;
  city: string;
  state?: string | null;
  zip: string;
  country?: string | null;
};

export type ContractListFragmentFragment = {
  __typename?: 'Contract';
  id: string;
  contactName?: string | null;
  contactEmail?: string | null;
  contactPhone?: string | null;
  note: string;
  tenantName?: string | null;
  tenantEmail?: string | null;
  tenantPhone?: string | null;
  signingDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  deletedAt?: string | null;
  createdAt: string;
  canManage: boolean;
  mainProperty?: {
    __typename?: 'Property';
    address: string;
    rent: number;
    serviceFees: number;
    id: string;
    accountId: string;
    name: string;
    deletedAt?: string | null;
    canManage: boolean;
    coverPhoto?: {
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    } | null;
  } | null;
};

export type ContractFragmentFragment = {
  __typename?: 'Contract';
  deposit?: number | null;
  rent?: number | null;
  rentPeriod?: RentPeriod | null;
  rentFrequency?: number | null;
  rentDueDay?: number | null;
  deletedAt?: string | null;
  createdAt: string;
  id: string;
  contactName?: string | null;
  contactEmail?: string | null;
  contactPhone?: string | null;
  note: string;
  tenantName?: string | null;
  tenantEmail?: string | null;
  tenantPhone?: string | null;
  signingDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  canManage: boolean;
  tenantAddress?: {
    __typename?: 'Address';
    line1: string;
    line2?: string | null;
    city: string;
    state?: string | null;
    zip: string;
    country?: string | null;
  } | null;
  mainProperty?: {
    __typename?: 'Property';
    description: string;
    address: string;
    rent: number;
    serviceFees: number;
    id: string;
    accountId: string;
    name: string;
    deletedAt?: string | null;
    canManage: boolean;
    photos: Array<{
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    }>;
    services: Array<{
      __typename?: 'Service';
      id: string;
      name: string;
      description?: string | null;
      unit: string;
      price: number;
      cost: number;
      deletedAt?: string | null;
    }>;
    propertyFiles: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    filesForTenant: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    coverPhoto?: {
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    } | null;
  } | null;
  properties: Array<{
    __typename?: 'Property';
    address: string;
    rent: number;
    serviceFees: number;
    id: string;
    accountId: string;
    name: string;
    deletedAt?: string | null;
    canManage: boolean;
    coverPhoto?: {
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    } | null;
  }>;
  services: Array<{
    __typename?: 'ContractedService';
    id: string;
    serviceId: string;
    name: string;
    note?: string | null;
    paymentType: ServicePaymentType;
    unit: string;
    price: number;
    cost: number;
  }>;
  files: Array<{
    __typename?: 'FileRef';
    id: string;
    name?: string | null;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
    deletedAt?: string | null;
  }>;
};

export type ContractedServiceFragmentFragment = {
  __typename?: 'ContractedService';
  id: string;
  serviceId: string;
  name: string;
  note?: string | null;
  paymentType: ServicePaymentType;
  unit: string;
  price: number;
  cost: number;
};

export type FileRefFragmentFragment = {
  __typename?: 'FileRef';
  id: string;
  name?: string | null;
  desc?: string | null;
  url: string;
  mimeType: string;
  size: number;
  uploadedAt?: string | null;
  deletedAt?: string | null;
};

export type PropertyPhotoFragmentFragment = {
  __typename?: 'PropertyPhoto';
  id: string;
  desc?: string | null;
  url: string;
  mimeType: string;
  size: number;
  uploadedAt?: string | null;
};

export type PropertyBaseFragmentFragment = {
  __typename?: 'Property';
  id: string;
  accountId: string;
  name: string;
  deletedAt?: string | null;
  canManage: boolean;
};

export type PropertyListFragmentFragment = {
  __typename?: 'Property';
  address: string;
  rent: number;
  serviceFees: number;
  id: string;
  accountId: string;
  name: string;
  deletedAt?: string | null;
  canManage: boolean;
  coverPhoto?: {
    __typename?: 'PropertyPhoto';
    id: string;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
  } | null;
};

export type PropertyFragmentFragment = {
  __typename?: 'Property';
  description: string;
  address: string;
  rent: number;
  serviceFees: number;
  id: string;
  accountId: string;
  name: string;
  deletedAt?: string | null;
  canManage: boolean;
  photos: Array<{
    __typename?: 'PropertyPhoto';
    id: string;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
  }>;
  services: Array<{
    __typename?: 'Service';
    id: string;
    name: string;
    description?: string | null;
    unit: string;
    price: number;
    cost: number;
    deletedAt?: string | null;
  }>;
  propertyFiles: Array<{
    __typename?: 'FileRef';
    id: string;
    name?: string | null;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
    deletedAt?: string | null;
  }>;
  filesForTenant: Array<{
    __typename?: 'FileRef';
    id: string;
    name?: string | null;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
    deletedAt?: string | null;
  }>;
  coverPhoto?: {
    __typename?: 'PropertyPhoto';
    id: string;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
  } | null;
};

export type RequestErrorFragmentFragment = { __typename?: 'RequestError'; code: any; field?: any | null };

export type ServiceFragmentFragment = {
  __typename?: 'Service';
  id: string;
  name: string;
  description?: string | null;
  unit: string;
  price: number;
  cost: number;
  deletedAt?: string | null;
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  email: any;
  phone?: any | null;
  isAdmin?: boolean | null;
  isSuperAdmin?: boolean | null;
  createdAt: string;
  disabledAt?: string | null;
  deletedAt?: string | null;
  account: {
    __typename?: 'Account';
    name: string;
    ownerId: string;
    disabledAt?: string | null;
    deletedAt?: string | null;
    canManage: boolean;
    services: Array<{
      __typename?: 'Service';
      id: string;
      name: string;
      description?: string | null;
      unit: string;
      price: number;
      cost: number;
      deletedAt?: string | null;
    }>;
  };
};

export type PropertyPayloadFragmentFragment = {
  __typename?: 'PropertyPayload';
  property?: {
    __typename?: 'Property';
    description: string;
    address: string;
    rent: number;
    serviceFees: number;
    id: string;
    accountId: string;
    name: string;
    deletedAt?: string | null;
    canManage: boolean;
    photos: Array<{
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    }>;
    services: Array<{
      __typename?: 'Service';
      id: string;
      name: string;
      description?: string | null;
      unit: string;
      price: number;
      cost: number;
      deletedAt?: string | null;
    }>;
    propertyFiles: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    filesForTenant: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    coverPhoto?: {
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    } | null;
  } | null;
  errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
};

export type CreatePropertyMutationVariables = Exact<{
  input: CreatePropertyInput;
}>;

export type CreatePropertyMutation = {
  __typename?: 'Mutation';
  createProperty: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type UpdatePropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePropertyInput;
}>;

export type UpdatePropertyMutation = {
  __typename?: 'Mutation';
  updateProperty: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type DeletePropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePropertyMutation = {
  __typename?: 'Mutation';
  deleteProperty: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type SetPropertyCoverPhotoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
}>;

export type SetPropertyCoverPhotoMutation = {
  __typename?: 'Mutation';
  setPropertyCoverPhoto: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type ConfirmPropertyPhotoUploadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
  size: Scalars['Int']['input'];
}>;

export type ConfirmPropertyPhotoUploadMutation = {
  __typename?: 'Mutation';
  confirmPropertyPhotoUpload: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type AddPropertyPhotoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: FileRefUploadInput;
}>;

export type AddPropertyPhotoMutation = {
  __typename?: 'Mutation';
  addPropertyPhoto: { __typename?: 'FileRefUploadPayload'; uploadUrl: string; fileId: string };
};

export type DeletePropertyPhotoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
}>;

export type DeletePropertyPhotoMutation = {
  __typename?: 'Mutation';
  deletePropertyPhoto: {
    __typename?: 'PropertyPayload';
    property?: {
      __typename?: 'Property';
      description: string;
      address: string;
      rent: number;
      serviceFees: number;
      id: string;
      accountId: string;
      name: string;
      deletedAt?: string | null;
      canManage: boolean;
      photos: Array<{
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      }>;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        description?: string | null;
        unit: string;
        price: number;
        cost: number;
        deletedAt?: string | null;
      }>;
      propertyFiles: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      filesForTenant: Array<{
        __typename?: 'FileRef';
        id: string;
        name?: string | null;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
        deletedAt?: string | null;
      }>;
      coverPhoto?: {
        __typename?: 'PropertyPhoto';
        id: string;
        desc?: string | null;
        url: string;
        mimeType: string;
        size: number;
        uploadedAt?: string | null;
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type UpdatePropertyPhotoCaptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
  caption: Scalars['String']['input'];
}>;

export type UpdatePropertyPhotoCaptionMutation = {
  __typename?: 'Mutation';
  updatePropertyPhotoCaption: {
    __typename?: 'PropertyPhotoPayload';
    photo?: {
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export type AddFileToPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  type: FileRefDocType;
  input: FileRefUploadInput;
}>;

export type AddFileToPropertyMutation = {
  __typename?: 'Mutation';
  addFileToProperty: { __typename?: 'FileRefUploadPayload'; uploadUrl: string; fileId: string };
};

export type ConfirmFileUploadedToPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
  size: Scalars['Int']['input'];
}>;

export type ConfirmFileUploadedToPropertyMutation = {
  __typename?: 'Mutation';
  confirmFileUploadedToProperty: {
    __typename?: 'FileRef';
    id: string;
    name?: string | null;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
    deletedAt?: string | null;
  };
};

export type DeleteFileFromPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
}>;

export type DeleteFileFromPropertyMutation = {
  __typename?: 'Mutation';
  deleteFileFromProperty: {
    __typename?: 'FileRef';
    id: string;
    name?: string | null;
    desc?: string | null;
    url: string;
    mimeType: string;
    size: number;
    uploadedAt?: string | null;
    deletedAt?: string | null;
  };
};

export type GetPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPropertiesQuery = {
  __typename?: 'Query';
  getProperties: Array<{
    __typename?: 'Property';
    description: string;
    address: string;
    rent: number;
    serviceFees: number;
    id: string;
    accountId: string;
    name: string;
    deletedAt?: string | null;
    canManage: boolean;
    photos: Array<{
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    }>;
    services: Array<{
      __typename?: 'Service';
      id: string;
      name: string;
      description?: string | null;
      unit: string;
      price: number;
      cost: number;
      deletedAt?: string | null;
    }>;
    propertyFiles: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    filesForTenant: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    coverPhoto?: {
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    } | null;
  }>;
};

export type GetPropertyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPropertyQuery = {
  __typename?: 'Query';
  getProperty: {
    __typename?: 'Property';
    description: string;
    address: string;
    rent: number;
    serviceFees: number;
    id: string;
    accountId: string;
    name: string;
    deletedAt?: string | null;
    canManage: boolean;
    photos: Array<{
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    }>;
    services: Array<{
      __typename?: 'Service';
      id: string;
      name: string;
      description?: string | null;
      unit: string;
      price: number;
      cost: number;
      deletedAt?: string | null;
    }>;
    propertyFiles: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    filesForTenant: Array<{
      __typename?: 'FileRef';
      id: string;
      name?: string | null;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
      deletedAt?: string | null;
    }>;
    coverPhoto?: {
      __typename?: 'PropertyPhoto';
      id: string;
      desc?: string | null;
      url: string;
      mimeType: string;
      size: number;
      uploadedAt?: string | null;
    } | null;
  };
};

export type GetUserByUsernameQueryVariables = Exact<{
  username?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUserByUsernameQuery = {
  __typename?: 'Query';
  getUserByUsername: {
    __typename?: 'UserPayload';
    user?: {
      __typename?: 'User';
      id: string;
      username: string;
      firstName?: string | null;
      lastName?: string | null;
      email: any;
      phone?: any | null;
      isAdmin?: boolean | null;
      isSuperAdmin?: boolean | null;
      createdAt: string;
      disabledAt?: string | null;
      deletedAt?: string | null;
      account: {
        __typename?: 'Account';
        name: string;
        ownerId: string;
        disabledAt?: string | null;
        deletedAt?: string | null;
        canManage: boolean;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          description?: string | null;
          unit: string;
          price: number;
          cost: number;
          deletedAt?: string | null;
        }>;
      };
    } | null;
    errors?: Array<{ __typename?: 'RequestError'; code: any; field?: any | null }> | null;
  };
};

export const PropertyBaseFragmentFragmentDoc = gql`
  fragment PropertyBaseFragment on Property {
    id
    accountId
    name
    deletedAt
    canManage
  }
`;
export const PropertyPhotoFragmentFragmentDoc = gql`
  fragment PropertyPhotoFragment on PropertyPhoto {
    id
    desc
    url
    mimeType
    size
    uploadedAt
  }
`;
export const PropertyListFragmentFragmentDoc = gql`
  fragment PropertyListFragment on Property {
    ...PropertyBaseFragment
    address
    rent
    serviceFees
    coverPhoto {
      ...PropertyPhotoFragment
    }
  }
  ${PropertyBaseFragmentFragmentDoc}
  ${PropertyPhotoFragmentFragmentDoc}
`;
export const ContractListFragmentFragmentDoc = gql`
  fragment ContractListFragment on Contract {
    id
    contactName
    contactEmail
    contactPhone
    note
    tenantName
    tenantEmail
    tenantPhone
    signingDate
    startDate
    endDate
    deletedAt
    createdAt
    mainProperty {
      ...PropertyListFragment
    }
    canManage
  }
  ${PropertyListFragmentFragmentDoc}
`;
export const AddressFragmentFragmentDoc = gql`
  fragment AddressFragment on Address {
    line1
    line2
    city
    state
    zip
    country
  }
`;
export const ServiceFragmentFragmentDoc = gql`
  fragment ServiceFragment on Service {
    id
    name
    description
    unit
    price
    cost
    deletedAt
  }
`;
export const FileRefFragmentFragmentDoc = gql`
  fragment FileRefFragment on FileRef {
    id
    name
    desc
    url
    mimeType
    size
    uploadedAt
    deletedAt
  }
`;
export const PropertyFragmentFragmentDoc = gql`
  fragment PropertyFragment on Property {
    ...PropertyListFragment
    description
    photos {
      ...PropertyPhotoFragment
    }
    services {
      ...ServiceFragment
    }
    propertyFiles {
      ...FileRefFragment
    }
    filesForTenant {
      ...FileRefFragment
    }
  }
  ${PropertyListFragmentFragmentDoc}
  ${PropertyPhotoFragmentFragmentDoc}
  ${ServiceFragmentFragmentDoc}
  ${FileRefFragmentFragmentDoc}
`;
export const ContractedServiceFragmentFragmentDoc = gql`
  fragment ContractedServiceFragment on ContractedService {
    id
    serviceId
    name
    note
    paymentType
    unit
    price
    cost
  }
`;
export const ContractFragmentFragmentDoc = gql`
  fragment ContractFragment on Contract {
    ...ContractListFragment
    tenantAddress {
      ...AddressFragment
    }
    deposit
    rent
    rentPeriod
    rentFrequency
    rentDueDay
    deletedAt
    createdAt
    mainProperty {
      ...PropertyFragment
    }
    properties {
      ...PropertyListFragment
    }
    services {
      ...ContractedServiceFragment
    }
    files {
      ...FileRefFragment
    }
  }
  ${ContractListFragmentFragmentDoc}
  ${AddressFragmentFragmentDoc}
  ${PropertyFragmentFragmentDoc}
  ${PropertyListFragmentFragmentDoc}
  ${ContractedServiceFragmentFragmentDoc}
  ${FileRefFragmentFragmentDoc}
`;
export const RequestErrorFragmentFragmentDoc = gql`
  fragment RequestErrorFragment on RequestError {
    code
    field
  }
`;
export const ContractPayloadFragmentFragmentDoc = gql`
  fragment ContractPayloadFragment on ContractPayload {
    contract {
      ...ContractFragment
    }
    errors {
      ...RequestErrorFragment
    }
  }
  ${ContractFragmentFragmentDoc}
  ${RequestErrorFragmentFragmentDoc}
`;
export const AccountFragmentFragmentDoc = gql`
  fragment AccountFragment on Account {
    name
    ownerId
    services {
      ...ServiceFragment
    }
    disabledAt
    deletedAt
    canManage
  }
  ${ServiceFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    username
    firstName
    lastName
    email
    phone
    isAdmin
    isSuperAdmin
    createdAt
    disabledAt
    deletedAt
    account {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export const PropertyPayloadFragmentFragmentDoc = gql`
  fragment PropertyPayloadFragment on PropertyPayload {
    property {
      ...PropertyFragment
    }
    errors {
      ...RequestErrorFragment
    }
  }
  ${PropertyFragmentFragmentDoc}
  ${RequestErrorFragmentFragmentDoc}
`;
export const CreateContractDocument = gql`
  mutation createContract {
    createContract {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type CreateContractMutationFn = Apollo.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, options);
}
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  CreateContractMutation,
  CreateContractMutationVariables
>;
export const UpdateContractDocument = gql`
  mutation updateContract($id: ID!, $input: UpdateContractInput!) {
    updateContract(id: $id, input: $input) {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type UpdateContractMutationFn = Apollo.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, options);
}
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = Apollo.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;
export const DeleteContractDocument = gql`
  mutation deleteContract($id: ID!) {
    deleteContract(id: $id) {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type DeleteContractMutationFn = Apollo.MutationFunction<DeleteContractMutation, DeleteContractMutationVariables>;

/**
 * __useDeleteContractMutation__
 *
 * To run a mutation, you first call `useDeleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractMutation, { data, loading, error }] = useDeleteContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContractMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContractMutation, DeleteContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContractMutation, DeleteContractMutationVariables>(DeleteContractDocument, options);
}
export type DeleteContractMutationHookResult = ReturnType<typeof useDeleteContractMutation>;
export type DeleteContractMutationResult = Apollo.MutationResult<DeleteContractMutation>;
export type DeleteContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteContractMutation,
  DeleteContractMutationVariables
>;
export const SignContractDocument = gql`
  mutation signContract($id: ID!, $signingDate: DateTime!) {
    signContract(id: $id, signingDate: $signingDate) {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type SignContractMutationFn = Apollo.MutationFunction<SignContractMutation, SignContractMutationVariables>;

/**
 * __useSignContractMutation__
 *
 * To run a mutation, you first call `useSignContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signContractMutation, { data, loading, error }] = useSignContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signingDate: // value for 'signingDate'
 *   },
 * });
 */
export function useSignContractMutation(
  baseOptions?: Apollo.MutationHookOptions<SignContractMutation, SignContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignContractMutation, SignContractMutationVariables>(SignContractDocument, options);
}
export type SignContractMutationHookResult = ReturnType<typeof useSignContractMutation>;
export type SignContractMutationResult = Apollo.MutationResult<SignContractMutation>;
export type SignContractMutationOptions = Apollo.BaseMutationOptions<
  SignContractMutation,
  SignContractMutationVariables
>;
export const AddPropertyToContractDocument = gql`
  mutation addPropertyToContract($id: ID!, $propertyId: ID!) {
    addPropertyToContract(id: $id, propertyId: $propertyId) {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type AddPropertyToContractMutationFn = Apollo.MutationFunction<
  AddPropertyToContractMutation,
  AddPropertyToContractMutationVariables
>;

/**
 * __useAddPropertyToContractMutation__
 *
 * To run a mutation, you first call `useAddPropertyToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyToContractMutation, { data, loading, error }] = useAddPropertyToContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useAddPropertyToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPropertyToContractMutation, AddPropertyToContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPropertyToContractMutation, AddPropertyToContractMutationVariables>(
    AddPropertyToContractDocument,
    options,
  );
}
export type AddPropertyToContractMutationHookResult = ReturnType<typeof useAddPropertyToContractMutation>;
export type AddPropertyToContractMutationResult = Apollo.MutationResult<AddPropertyToContractMutation>;
export type AddPropertyToContractMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyToContractMutation,
  AddPropertyToContractMutationVariables
>;
export const RemovePropertyFromContractDocument = gql`
  mutation removePropertyFromContract($id: ID!, $propertyId: ID!) {
    removePropertyFromContract(id: $id, propertyId: $propertyId) {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type RemovePropertyFromContractMutationFn = Apollo.MutationFunction<
  RemovePropertyFromContractMutation,
  RemovePropertyFromContractMutationVariables
>;

/**
 * __useRemovePropertyFromContractMutation__
 *
 * To run a mutation, you first call `useRemovePropertyFromContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePropertyFromContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePropertyFromContractMutation, { data, loading, error }] = useRemovePropertyFromContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useRemovePropertyFromContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePropertyFromContractMutation,
    RemovePropertyFromContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePropertyFromContractMutation, RemovePropertyFromContractMutationVariables>(
    RemovePropertyFromContractDocument,
    options,
  );
}
export type RemovePropertyFromContractMutationHookResult = ReturnType<typeof useRemovePropertyFromContractMutation>;
export type RemovePropertyFromContractMutationResult = Apollo.MutationResult<RemovePropertyFromContractMutation>;
export type RemovePropertyFromContractMutationOptions = Apollo.BaseMutationOptions<
  RemovePropertyFromContractMutation,
  RemovePropertyFromContractMutationVariables
>;
export const AddServiceToContractDocument = gql`
  mutation addServiceToContract($id: ID!, $input: ContractedServiceInput!) {
    addServiceToContract(id: $id, input: $input) {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type AddServiceToContractMutationFn = Apollo.MutationFunction<
  AddServiceToContractMutation,
  AddServiceToContractMutationVariables
>;

/**
 * __useAddServiceToContractMutation__
 *
 * To run a mutation, you first call `useAddServiceToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServiceToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServiceToContractMutation, { data, loading, error }] = useAddServiceToContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddServiceToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<AddServiceToContractMutation, AddServiceToContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddServiceToContractMutation, AddServiceToContractMutationVariables>(
    AddServiceToContractDocument,
    options,
  );
}
export type AddServiceToContractMutationHookResult = ReturnType<typeof useAddServiceToContractMutation>;
export type AddServiceToContractMutationResult = Apollo.MutationResult<AddServiceToContractMutation>;
export type AddServiceToContractMutationOptions = Apollo.BaseMutationOptions<
  AddServiceToContractMutation,
  AddServiceToContractMutationVariables
>;
export const RemoveServiceFromContractDocument = gql`
  mutation removeServiceFromContract($id: ID!, $serviceId: ID!) {
    removeServiceFromContract(id: $id, serviceId: $serviceId) {
      ...ContractPayloadFragment
    }
  }
  ${ContractPayloadFragmentFragmentDoc}
`;
export type RemoveServiceFromContractMutationFn = Apollo.MutationFunction<
  RemoveServiceFromContractMutation,
  RemoveServiceFromContractMutationVariables
>;

/**
 * __useRemoveServiceFromContractMutation__
 *
 * To run a mutation, you first call `useRemoveServiceFromContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveServiceFromContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeServiceFromContractMutation, { data, loading, error }] = useRemoveServiceFromContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useRemoveServiceFromContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveServiceFromContractMutation,
    RemoveServiceFromContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveServiceFromContractMutation, RemoveServiceFromContractMutationVariables>(
    RemoveServiceFromContractDocument,
    options,
  );
}
export type RemoveServiceFromContractMutationHookResult = ReturnType<typeof useRemoveServiceFromContractMutation>;
export type RemoveServiceFromContractMutationResult = Apollo.MutationResult<RemoveServiceFromContractMutation>;
export type RemoveServiceFromContractMutationOptions = Apollo.BaseMutationOptions<
  RemoveServiceFromContractMutation,
  RemoveServiceFromContractMutationVariables
>;
export const AddFileToContractDocument = gql`
  mutation addFileToContract($id: ID!, $type: FileRefDocType!, $input: FileRefUploadInput!) {
    addFileToContract(id: $id, type: $type, input: $input) {
      uploadUrl
      fileId
    }
  }
`;
export type AddFileToContractMutationFn = Apollo.MutationFunction<
  AddFileToContractMutation,
  AddFileToContractMutationVariables
>;

/**
 * __useAddFileToContractMutation__
 *
 * To run a mutation, you first call `useAddFileToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileToContractMutation, { data, loading, error }] = useAddFileToContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFileToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<AddFileToContractMutation, AddFileToContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFileToContractMutation, AddFileToContractMutationVariables>(
    AddFileToContractDocument,
    options,
  );
}
export type AddFileToContractMutationHookResult = ReturnType<typeof useAddFileToContractMutation>;
export type AddFileToContractMutationResult = Apollo.MutationResult<AddFileToContractMutation>;
export type AddFileToContractMutationOptions = Apollo.BaseMutationOptions<
  AddFileToContractMutation,
  AddFileToContractMutationVariables
>;
export const ConfirmFileUploadedToContractDocument = gql`
  mutation confirmFileUploadedToContract($id: ID!, $fileId: ID!, $size: Int!) {
    confirmFileUploadedToContract(id: $id, fileId: $fileId, size: $size) {
      ...FileRefFragment
    }
  }
  ${FileRefFragmentFragmentDoc}
`;
export type ConfirmFileUploadedToContractMutationFn = Apollo.MutationFunction<
  ConfirmFileUploadedToContractMutation,
  ConfirmFileUploadedToContractMutationVariables
>;

/**
 * __useConfirmFileUploadedToContractMutation__
 *
 * To run a mutation, you first call `useConfirmFileUploadedToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmFileUploadedToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmFileUploadedToContractMutation, { data, loading, error }] = useConfirmFileUploadedToContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileId: // value for 'fileId'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useConfirmFileUploadedToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmFileUploadedToContractMutation,
    ConfirmFileUploadedToContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmFileUploadedToContractMutation, ConfirmFileUploadedToContractMutationVariables>(
    ConfirmFileUploadedToContractDocument,
    options,
  );
}
export type ConfirmFileUploadedToContractMutationHookResult = ReturnType<
  typeof useConfirmFileUploadedToContractMutation
>;
export type ConfirmFileUploadedToContractMutationResult = Apollo.MutationResult<ConfirmFileUploadedToContractMutation>;
export type ConfirmFileUploadedToContractMutationOptions = Apollo.BaseMutationOptions<
  ConfirmFileUploadedToContractMutation,
  ConfirmFileUploadedToContractMutationVariables
>;
export const DeleteFileFromContractDocument = gql`
  mutation deleteFileFromContract($id: ID!, $fileId: ID!) {
    deleteFileFromContract(id: $id, fileId: $fileId) {
      ...FileRefFragment
    }
  }
  ${FileRefFragmentFragmentDoc}
`;
export type DeleteFileFromContractMutationFn = Apollo.MutationFunction<
  DeleteFileFromContractMutation,
  DeleteFileFromContractMutationVariables
>;

/**
 * __useDeleteFileFromContractMutation__
 *
 * To run a mutation, you first call `useDeleteFileFromContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileFromContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileFromContractMutation, { data, loading, error }] = useDeleteFileFromContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteFileFromContractMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileFromContractMutation, DeleteFileFromContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileFromContractMutation, DeleteFileFromContractMutationVariables>(
    DeleteFileFromContractDocument,
    options,
  );
}
export type DeleteFileFromContractMutationHookResult = ReturnType<typeof useDeleteFileFromContractMutation>;
export type DeleteFileFromContractMutationResult = Apollo.MutationResult<DeleteFileFromContractMutation>;
export type DeleteFileFromContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileFromContractMutation,
  DeleteFileFromContractMutationVariables
>;
export const GetContractsDocument = gql`
  query getContracts {
    getContracts {
      ...ContractFragment
    }
  }
  ${ContractFragmentFragmentDoc}
`;

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
}
export function useGetContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
}
export function useGetContractsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
}
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsSuspenseQueryHookResult = ReturnType<typeof useGetContractsSuspenseQuery>;
export type GetContractsQueryResult = Apollo.QueryResult<GetContractsQuery, GetContractsQueryVariables>;
export const GetContractDocument = gql`
  query getContract($id: ID!) {
    getContract(id: $id) {
      ...ContractFragment
    }
  }
  ${ContractFragmentFragmentDoc}
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions: Apollo.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, options);
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, options);
}
export function useGetContractSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetContractQuery, GetContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, options);
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractSuspenseQueryHookResult = ReturnType<typeof useGetContractSuspenseQuery>;
export type GetContractQueryResult = Apollo.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const CreatePropertyDocument = gql`
  mutation createProperty($input: CreatePropertyInput!) {
    createProperty(input: $input) {
      ...PropertyPayloadFragment
    }
  }
  ${PropertyPayloadFragmentFragmentDoc}
`;
export type CreatePropertyMutationFn = Apollo.MutationFunction<CreatePropertyMutation, CreatePropertyMutationVariables>;

/**
 * __useCreatePropertyMutation__
 *
 * To run a mutation, you first call `useCreatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyMutation, { data, loading, error }] = useCreatePropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePropertyMutation, CreatePropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePropertyMutation, CreatePropertyMutationVariables>(CreatePropertyDocument, options);
}
export type CreatePropertyMutationHookResult = ReturnType<typeof useCreatePropertyMutation>;
export type CreatePropertyMutationResult = Apollo.MutationResult<CreatePropertyMutation>;
export type CreatePropertyMutationOptions = Apollo.BaseMutationOptions<
  CreatePropertyMutation,
  CreatePropertyMutationVariables
>;
export const UpdatePropertyDocument = gql`
  mutation updateProperty($id: ID!, $input: UpdatePropertyInput!) {
    updateProperty(id: $id, input: $input) {
      ...PropertyPayloadFragment
    }
  }
  ${PropertyPayloadFragmentFragmentDoc}
`;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<UpdatePropertyMutation, UpdatePropertyMutationVariables>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(UpdatePropertyDocument, options);
}
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;
export const DeletePropertyDocument = gql`
  mutation deleteProperty($id: ID!) {
    deleteProperty(id: $id) {
      ...PropertyPayloadFragment
    }
  }
  ${PropertyPayloadFragmentFragmentDoc}
`;
export type DeletePropertyMutationFn = Apollo.MutationFunction<DeletePropertyMutation, DeletePropertyMutationVariables>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePropertyMutation, DeletePropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePropertyMutation, DeletePropertyMutationVariables>(DeletePropertyDocument, options);
}
export type DeletePropertyMutationHookResult = ReturnType<typeof useDeletePropertyMutation>;
export type DeletePropertyMutationResult = Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;
export const SetPropertyCoverPhotoDocument = gql`
  mutation setPropertyCoverPhoto($id: ID!, $photoId: ID!) {
    setPropertyCoverPhoto(id: $id, photoId: $photoId) {
      ...PropertyPayloadFragment
    }
  }
  ${PropertyPayloadFragmentFragmentDoc}
`;
export type SetPropertyCoverPhotoMutationFn = Apollo.MutationFunction<
  SetPropertyCoverPhotoMutation,
  SetPropertyCoverPhotoMutationVariables
>;

/**
 * __useSetPropertyCoverPhotoMutation__
 *
 * To run a mutation, you first call `useSetPropertyCoverPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropertyCoverPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropertyCoverPhotoMutation, { data, loading, error }] = useSetPropertyCoverPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useSetPropertyCoverPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<SetPropertyCoverPhotoMutation, SetPropertyCoverPhotoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetPropertyCoverPhotoMutation, SetPropertyCoverPhotoMutationVariables>(
    SetPropertyCoverPhotoDocument,
    options,
  );
}
export type SetPropertyCoverPhotoMutationHookResult = ReturnType<typeof useSetPropertyCoverPhotoMutation>;
export type SetPropertyCoverPhotoMutationResult = Apollo.MutationResult<SetPropertyCoverPhotoMutation>;
export type SetPropertyCoverPhotoMutationOptions = Apollo.BaseMutationOptions<
  SetPropertyCoverPhotoMutation,
  SetPropertyCoverPhotoMutationVariables
>;
export const ConfirmPropertyPhotoUploadDocument = gql`
  mutation confirmPropertyPhotoUpload($id: ID!, $photoId: ID!, $size: Int!) {
    confirmPropertyPhotoUpload(id: $id, photoId: $photoId, size: $size) {
      ...PropertyPayloadFragment
    }
  }
  ${PropertyPayloadFragmentFragmentDoc}
`;
export type ConfirmPropertyPhotoUploadMutationFn = Apollo.MutationFunction<
  ConfirmPropertyPhotoUploadMutation,
  ConfirmPropertyPhotoUploadMutationVariables
>;

/**
 * __useConfirmPropertyPhotoUploadMutation__
 *
 * To run a mutation, you first call `useConfirmPropertyPhotoUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPropertyPhotoUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPropertyPhotoUploadMutation, { data, loading, error }] = useConfirmPropertyPhotoUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photoId: // value for 'photoId'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useConfirmPropertyPhotoUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPropertyPhotoUploadMutation,
    ConfirmPropertyPhotoUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmPropertyPhotoUploadMutation, ConfirmPropertyPhotoUploadMutationVariables>(
    ConfirmPropertyPhotoUploadDocument,
    options,
  );
}
export type ConfirmPropertyPhotoUploadMutationHookResult = ReturnType<typeof useConfirmPropertyPhotoUploadMutation>;
export type ConfirmPropertyPhotoUploadMutationResult = Apollo.MutationResult<ConfirmPropertyPhotoUploadMutation>;
export type ConfirmPropertyPhotoUploadMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPropertyPhotoUploadMutation,
  ConfirmPropertyPhotoUploadMutationVariables
>;
export const AddPropertyPhotoDocument = gql`
  mutation addPropertyPhoto($id: ID!, $input: FileRefUploadInput!) {
    addPropertyPhoto(id: $id, input: $input) {
      uploadUrl
      fileId
    }
  }
`;
export type AddPropertyPhotoMutationFn = Apollo.MutationFunction<
  AddPropertyPhotoMutation,
  AddPropertyPhotoMutationVariables
>;

/**
 * __useAddPropertyPhotoMutation__
 *
 * To run a mutation, you first call `useAddPropertyPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyPhotoMutation, { data, loading, error }] = useAddPropertyPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPropertyPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPropertyPhotoMutation, AddPropertyPhotoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPropertyPhotoMutation, AddPropertyPhotoMutationVariables>(
    AddPropertyPhotoDocument,
    options,
  );
}
export type AddPropertyPhotoMutationHookResult = ReturnType<typeof useAddPropertyPhotoMutation>;
export type AddPropertyPhotoMutationResult = Apollo.MutationResult<AddPropertyPhotoMutation>;
export type AddPropertyPhotoMutationOptions = Apollo.BaseMutationOptions<
  AddPropertyPhotoMutation,
  AddPropertyPhotoMutationVariables
>;
export const DeletePropertyPhotoDocument = gql`
  mutation deletePropertyPhoto($id: ID!, $photoId: ID!) {
    deletePropertyPhoto(id: $id, photoId: $photoId) {
      ...PropertyPayloadFragment
    }
  }
  ${PropertyPayloadFragmentFragmentDoc}
`;
export type DeletePropertyPhotoMutationFn = Apollo.MutationFunction<
  DeletePropertyPhotoMutation,
  DeletePropertyPhotoMutationVariables
>;

/**
 * __useDeletePropertyPhotoMutation__
 *
 * To run a mutation, you first call `useDeletePropertyPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyPhotoMutation, { data, loading, error }] = useDeletePropertyPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useDeletePropertyPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePropertyPhotoMutation, DeletePropertyPhotoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePropertyPhotoMutation, DeletePropertyPhotoMutationVariables>(
    DeletePropertyPhotoDocument,
    options,
  );
}
export type DeletePropertyPhotoMutationHookResult = ReturnType<typeof useDeletePropertyPhotoMutation>;
export type DeletePropertyPhotoMutationResult = Apollo.MutationResult<DeletePropertyPhotoMutation>;
export type DeletePropertyPhotoMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyPhotoMutation,
  DeletePropertyPhotoMutationVariables
>;
export const UpdatePropertyPhotoCaptionDocument = gql`
  mutation updatePropertyPhotoCaption($id: ID!, $photoId: ID!, $caption: String!) {
    updatePropertyPhotoCaption(id: $id, photoId: $photoId, caption: $caption) {
      photo {
        ...PropertyPhotoFragment
      }
      errors {
        ...RequestErrorFragment
      }
    }
  }
  ${PropertyPhotoFragmentFragmentDoc}
  ${RequestErrorFragmentFragmentDoc}
`;
export type UpdatePropertyPhotoCaptionMutationFn = Apollo.MutationFunction<
  UpdatePropertyPhotoCaptionMutation,
  UpdatePropertyPhotoCaptionMutationVariables
>;

/**
 * __useUpdatePropertyPhotoCaptionMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyPhotoCaptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyPhotoCaptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyPhotoCaptionMutation, { data, loading, error }] = useUpdatePropertyPhotoCaptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photoId: // value for 'photoId'
 *      caption: // value for 'caption'
 *   },
 * });
 */
export function useUpdatePropertyPhotoCaptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyPhotoCaptionMutation,
    UpdatePropertyPhotoCaptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePropertyPhotoCaptionMutation, UpdatePropertyPhotoCaptionMutationVariables>(
    UpdatePropertyPhotoCaptionDocument,
    options,
  );
}
export type UpdatePropertyPhotoCaptionMutationHookResult = ReturnType<typeof useUpdatePropertyPhotoCaptionMutation>;
export type UpdatePropertyPhotoCaptionMutationResult = Apollo.MutationResult<UpdatePropertyPhotoCaptionMutation>;
export type UpdatePropertyPhotoCaptionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyPhotoCaptionMutation,
  UpdatePropertyPhotoCaptionMutationVariables
>;
export const AddFileToPropertyDocument = gql`
  mutation addFileToProperty($id: ID!, $type: FileRefDocType!, $input: FileRefUploadInput!) {
    addFileToProperty(id: $id, type: $type, input: $input) {
      uploadUrl
      fileId
    }
  }
`;
export type AddFileToPropertyMutationFn = Apollo.MutationFunction<
  AddFileToPropertyMutation,
  AddFileToPropertyMutationVariables
>;

/**
 * __useAddFileToPropertyMutation__
 *
 * To run a mutation, you first call `useAddFileToPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileToPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileToPropertyMutation, { data, loading, error }] = useAddFileToPropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFileToPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<AddFileToPropertyMutation, AddFileToPropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFileToPropertyMutation, AddFileToPropertyMutationVariables>(
    AddFileToPropertyDocument,
    options,
  );
}
export type AddFileToPropertyMutationHookResult = ReturnType<typeof useAddFileToPropertyMutation>;
export type AddFileToPropertyMutationResult = Apollo.MutationResult<AddFileToPropertyMutation>;
export type AddFileToPropertyMutationOptions = Apollo.BaseMutationOptions<
  AddFileToPropertyMutation,
  AddFileToPropertyMutationVariables
>;
export const ConfirmFileUploadedToPropertyDocument = gql`
  mutation confirmFileUploadedToProperty($id: ID!, $fileId: ID!, $size: Int!) {
    confirmFileUploadedToProperty(id: $id, fileId: $fileId, size: $size) {
      ...FileRefFragment
    }
  }
  ${FileRefFragmentFragmentDoc}
`;
export type ConfirmFileUploadedToPropertyMutationFn = Apollo.MutationFunction<
  ConfirmFileUploadedToPropertyMutation,
  ConfirmFileUploadedToPropertyMutationVariables
>;

/**
 * __useConfirmFileUploadedToPropertyMutation__
 *
 * To run a mutation, you first call `useConfirmFileUploadedToPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmFileUploadedToPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmFileUploadedToPropertyMutation, { data, loading, error }] = useConfirmFileUploadedToPropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileId: // value for 'fileId'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useConfirmFileUploadedToPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmFileUploadedToPropertyMutation,
    ConfirmFileUploadedToPropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmFileUploadedToPropertyMutation, ConfirmFileUploadedToPropertyMutationVariables>(
    ConfirmFileUploadedToPropertyDocument,
    options,
  );
}
export type ConfirmFileUploadedToPropertyMutationHookResult = ReturnType<
  typeof useConfirmFileUploadedToPropertyMutation
>;
export type ConfirmFileUploadedToPropertyMutationResult = Apollo.MutationResult<ConfirmFileUploadedToPropertyMutation>;
export type ConfirmFileUploadedToPropertyMutationOptions = Apollo.BaseMutationOptions<
  ConfirmFileUploadedToPropertyMutation,
  ConfirmFileUploadedToPropertyMutationVariables
>;
export const DeleteFileFromPropertyDocument = gql`
  mutation deleteFileFromProperty($id: ID!, $fileId: ID!) {
    deleteFileFromProperty(id: $id, fileId: $fileId) {
      ...FileRefFragment
    }
  }
  ${FileRefFragmentFragmentDoc}
`;
export type DeleteFileFromPropertyMutationFn = Apollo.MutationFunction<
  DeleteFileFromPropertyMutation,
  DeleteFileFromPropertyMutationVariables
>;

/**
 * __useDeleteFileFromPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteFileFromPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileFromPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileFromPropertyMutation, { data, loading, error }] = useDeleteFileFromPropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteFileFromPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileFromPropertyMutation, DeleteFileFromPropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileFromPropertyMutation, DeleteFileFromPropertyMutationVariables>(
    DeleteFileFromPropertyDocument,
    options,
  );
}
export type DeleteFileFromPropertyMutationHookResult = ReturnType<typeof useDeleteFileFromPropertyMutation>;
export type DeleteFileFromPropertyMutationResult = Apollo.MutationResult<DeleteFileFromPropertyMutation>;
export type DeleteFileFromPropertyMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileFromPropertyMutation,
  DeleteFileFromPropertyMutationVariables
>;
export const GetPropertiesDocument = gql`
  query getProperties {
    getProperties {
      ...PropertyFragment
    }
  }
  ${PropertyFragmentFragmentDoc}
`;

/**
 * __useGetPropertiesQuery__
 *
 * To run a query within a React component, call `useGetPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export function useGetPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export function useGetPropertiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export type GetPropertiesQueryHookResult = ReturnType<typeof useGetPropertiesQuery>;
export type GetPropertiesLazyQueryHookResult = ReturnType<typeof useGetPropertiesLazyQuery>;
export type GetPropertiesSuspenseQueryHookResult = ReturnType<typeof useGetPropertiesSuspenseQuery>;
export type GetPropertiesQueryResult = Apollo.QueryResult<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetPropertyDocument = gql`
  query getProperty($id: ID!) {
    getProperty(id: $id) {
      ...PropertyFragment
    }
  }
  ${PropertyFragmentFragmentDoc}
`;

/**
 * __useGetPropertyQuery__
 *
 * To run a query within a React component, call `useGetPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyQuery, GetPropertyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPropertyQuery, GetPropertyQueryVariables>(GetPropertyDocument, options);
}
export function useGetPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyQuery, GetPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPropertyQuery, GetPropertyQueryVariables>(GetPropertyDocument, options);
}
export function useGetPropertySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPropertyQuery, GetPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPropertyQuery, GetPropertyQueryVariables>(GetPropertyDocument, options);
}
export type GetPropertyQueryHookResult = ReturnType<typeof useGetPropertyQuery>;
export type GetPropertyLazyQueryHookResult = ReturnType<typeof useGetPropertyLazyQuery>;
export type GetPropertySuspenseQueryHookResult = ReturnType<typeof useGetPropertySuspenseQuery>;
export type GetPropertyQueryResult = Apollo.QueryResult<GetPropertyQuery, GetPropertyQueryVariables>;
export const GetUserByUsernameDocument = gql`
  query getUserByUsername($username: String) {
    getUserByUsername(username: $username) {
      user {
        ...UserFragment
      }
      errors {
        ...RequestErrorFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${RequestErrorFragmentFragmentDoc}
`;

/**
 * __useGetUserByUsernameQuery__
 *
 * To run a query within a React component, call `useGetUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserByUsernameQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, options);
}
export function useGetUserByUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(
    GetUserByUsernameDocument,
    options,
  );
}
export function useGetUserByUsernameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(
    GetUserByUsernameDocument,
    options,
  );
}
export type GetUserByUsernameQueryHookResult = ReturnType<typeof useGetUserByUsernameQuery>;
export type GetUserByUsernameLazyQueryHookResult = ReturnType<typeof useGetUserByUsernameLazyQuery>;
export type GetUserByUsernameSuspenseQueryHookResult = ReturnType<typeof useGetUserByUsernameSuspenseQuery>;
export type GetUserByUsernameQueryResult = Apollo.QueryResult<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>;
