import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface NewCardProps {
  width: number;
  label: string;
  onClick: () => void;
}

export function NewCard(props: NewCardProps) {
  return (
    <Card square elevation={0} sx={{ width: props.width, borderStyle: 'dashed', borderColor: '#00000020' }}>
      <CardActionArea sx={{ height: '100%' }} onClick={props.onClick}>
        <CardContent sx={{ textAlign: 'center' }}>
          <AddIcon fontSize="large" />
          <Typography variant="h5">{props.label}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
