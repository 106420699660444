import React, { useState } from 'react';
import { Stack } from '@mui/system';
import { CircularProgress, Typography } from '@mui/material';
import { BasePage } from '../../components/base-page';
import { PropertyCard } from '../../components/property-card';
import {
  useCreatePropertyMutation,
  useGetPropertiesQuery,
  useDeletePropertyMutation,
  PropertyFragmentFragment,
} from '../../_generated/graphql';
import { useNavigate } from 'react-router-dom';
import { appPaths } from '../../app-paths';
import { NewPropertyDialog } from '../../components/new-property-dialog';
import { NewCard } from '../../components/new-card';
import { ConfirmationDialog } from '../../components/confirmation-dialog';
import { useAlert } from '@snuglyrent/react-alert';

const cardWidth = 300;

export function PropertyListPage() {
  const navigate = useNavigate();
  const { show } = useAlert();
  const [showAddNewProperty, setShowAddNewProperty] = useState<boolean>(false);

  const {
    data: propData,
    refetch: refetchProperties,
    loading: propertiesLoading,
  } = useGetPropertiesQuery({
    onError: (e) => show.error('Nepodarilo sa načítať nehnuteľnosti.', e.message),
  });
  const [createProperty, { loading: createLoading }] = useCreatePropertyMutation({
    onError: (e) => {
      show.error('Nepodarilo sa vytvoriť nehnuteľnosť.', e.message);
    },
  });

  const [deleteProperty, { loading: deleteLoading }] = useDeletePropertyMutation({
    onError: (e) => {
      show.error('Nepodarilo sa zmazať nehnuteľnosť.', e.message);
    },
  });
  const [propertyToDelete, setPropertyToDelete] = useState<PropertyFragmentFragment | null>(null);

  const handleAddNewProperty = (name: string) => {
    createProperty({
      variables: { input: { name } },
      onCompleted: () => {
        setShowAddNewProperty(false);
        refetchProperties();
      },
    });
  };
  const handleDeleteProperty = (property: PropertyFragmentFragment) => {
    deleteProperty({ variables: { id: property.id } });
    setPropertyToDelete(null);
  };

  const properties = propData?.getProperties ?? [];
  return (
    <BasePage>
      {propertyToDelete !== null && (
        <ConfirmationDialog
          title="Potvrdenie zmazania"
          content={`Ste si istý, že chcete vymazať nehnuteľnosť '${propertyToDelete.name}'?`}
          loading={deleteLoading}
          cancelLabel="Nie"
          onCancel={() => setPropertyToDelete(null)}
          confirmLabel="Áno"
          onConfirm={() => handleDeleteProperty(propertyToDelete)}
        />
      )}

      <Typography variant="h1">Nehnuteľnosti</Typography>
      {propertiesLoading ? (
        <CircularProgress />
      ) : (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} useFlexGap flexWrap="wrap">
          <NewCard width={cardWidth} label="Pridaj nehnuteľnosť" onClick={() => setShowAddNewProperty(true)} />
          {properties
            .filter((p) => !p.deletedAt)
            .map((p) => (
              <PropertyCard
                key={p.id}
                property={p}
                width={cardWidth}
                onEdit={() => navigate(appPaths.property(p.id))}
                onDelete={() => setPropertyToDelete(p)}
              />
            ))}
        </Stack>
      )}

      {showAddNewProperty && (
        <NewPropertyDialog
          onClose={() => setShowAddNewProperty(false)}
          onSubmit={handleAddNewProperty}
          loading={createLoading}
        />
      )}
    </BasePage>
  );
}
