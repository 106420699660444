import React from 'react';

import { Typography } from '@mui/material';
import { BasePage } from '../../components/base-page';
import { useCreateContractMutation, useGetContractsQuery } from '../../_generated/graphql';
import { useNavigate } from 'react-router-dom';
import { appPaths } from '../../app-paths';

export function ContractListPage() {
  const navigate = useNavigate();
  const { data: contData } = useGetContractsQuery();
  const [createContract] = useCreateContractMutation({ onError: (e) => console.log(e) });

  const contracts = contData?.getContracts ?? [];

  const handleCreateContract = async () => {
    //TODO: block UI
    const nc = await createContract();
    if (nc.data?.createContract.contract?.id) {
      navigate(appPaths.contract(nc.data.createContract.contract.id));
    }
    //TODO: unblock UI
  };

  return (
    <BasePage>
      <Typography variant="h4">Prenájmy</Typography>
      {contracts
        .filter((c) => !c.deletedAt)
        .map((c) => (
          <div key={c.id}>
            {!!c.signingDate && (
              <Typography>
                Nájomca {c.tenantName} zo dna {new Date(c.signingDate).toLocaleDateString()}
              </Typography>
            )}
            {!c.signingDate && (
              <Typography>
                Zaujemca {c.contactName} zo dna {new Date(c.createdAt).toLocaleDateString()}{' '}
              </Typography>
            )}

            <button onClick={() => navigate(appPaths.contract(c.id))}>Otvor</button>
          </div>
        ))}
      <button onClick={handleCreateContract}>Add new contract</button>
    </BasePage>
  );
}
