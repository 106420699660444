import React, { useMemo } from 'react';
import { AlertType, useAlert } from './provider';
import { Alert, AlertComponentProps } from './alert';

interface AlertsProps {
  component?: (props: AlertComponentProps) => React.ReactNode;
  verticalAlign?: 'top' | 'bottom';
  horizontalAlign?: 'left' | 'right' | 'center';
  zIndex?: number;
  width?: number;
}

/**
 * Component for displaying alerts. It has to be placed inside the AlertProvider component.
 * @param component Custom component for displaying alerts. If not provided, default Alert component is used.
 * @param zIndex Z-index of the alerts container.
 * @param horizontalAlign Horizontal alignment of the alerts container.
 * @param verticalAlign Vertical alignment of the alerts container.
 * @param width Width of the alerts container.
 * @returns React component
 */
export function Alerts(props: AlertsProps) {
  const { component, zIndex = 9000, horizontalAlign = 'left', verticalAlign = 'bottom', width = 300 } = props;
  const { alerts, remove } = useAlert();
  const renderAlert = useMemo(
    () =>
      component
        ? (a: AlertType) => component({ ...a, onClose: () => remove(a.id) })
        : (a: AlertType) => (
            <Alert
              key={a.id}
              message={a.message}
              details={a.details}
              variant={a.variant}
              onClose={() => remove(a.id)}
            />
          ),
    [component, remove],
  );

  return (
    <div
      id="react-alert-canvas"
      style={{
        position: 'fixed',
        top: verticalAlign === 'top' ? 0 : undefined,
        bottom: verticalAlign === 'bottom' ? 0 : undefined,
        left: horizontalAlign === 'left' ? 0 : horizontalAlign === 'center' ? `calc(50% - ${width / 2}px)` : undefined,
        right: horizontalAlign === 'right' ? 0 : undefined,
        justifySelf: horizontalAlign,

        padding: '16px',
        zIndex,
        width,
        display: 'flex',
        flexDirection: verticalAlign === 'top' ? 'column' : 'column-reverse',
      }}
    >
      {alerts.map((a) => renderAlert(a))}
    </div>
  );
}
