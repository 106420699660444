import React from 'react';
import { PropertyFragmentFragment } from '../../../_generated/graphql';
import { Box } from '@mui/material';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

export function PropertyCoverPhoto(props: { property: PropertyFragmentFragment }) {
  const hasCoverPhoto = !!props.property?.coverPhoto?.url;
  return (
    <Box
      sx={{
        width: 200,
        height: 200,
        overflow: 'hidden',
        marginTop: 2,
        marginBottom: 2,
        backgroundColor: hasCoverPhoto ? undefined : 'lightgray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {hasCoverPhoto ? (
        <img
          height="200"
          src={props.property.coverPhoto?.url}
          alt={props.property.coverPhoto?.desc ?? undefined}
          loading="lazy"
        />
      ) : (
        <NoPhotographyIcon fontSize="large" />
      )}
    </Box>
  );
}
