import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@snuglyrent/auth-react';

import { Box, Stack } from '@mui/material';
import { TopBar } from './top-bar';
import { MainNavbar } from './main-navbar';
import { IUserData } from '../types';
import { appPaths } from '../app-paths';

export function BasePage({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { logout } = useAuth<IUserData>();

  const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate(appPaths.signin());
  };

  return (
    <Stack direction="column" sx={{ height: '100%' }}>
      <TopBar onLogout={handleLogout} onMobileNavigationToggle={() => setMobileNavigationOpen(!mobileNavigationOpen)} />
      <Stack direction="row" sx={{ flexGrow: 1 }}>
        <MainNavbar
          onMobileNavigationClosed={() => setMobileNavigationOpen(false)}
          mobileNavigationOpen={mobileNavigationOpen}
        />
        <Box sx={{ py: 2, px: 3, width: '100%' }}>{children}</Box>
      </Stack>
    </Stack>
  );
}
