import React, { PropsWithChildren } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';

export function ThemeProvider(props: PropsWithChildren) {
  const hBase = { color: '#43332A' };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#43332A',
        contrastText: '#EDE4D5',
      },
      secondary: {
        main: '#EDE4D5',
        contrastText: '#79704A',
      },
      text: {
        primary: '#43332A',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: { ...hBase, fontSize: '2.3rem', fontWeight: '400', marginBottom: '1.8rem' },
          h2: { ...hBase, fontSize: '1.9rem', fontWeight: '300', margin: '1.3rem 0 1.3rem 0' },
          h3: { ...hBase, fontSize: '1.6rem', fontWeight: '300', margin: '1rem 0 1rem 0' },
          h4: { ...hBase, fontSize: '1.3rem', fontWeight: '500', margin: '0.7rem 0 0.7rem 0' },
          h5: { fontSize: '1.1rem', fontWeight: '400', margin: '0.3rem 0 0.3rem 0' },
        },
      },
    },
  });

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}
