import React from 'react';
import { CircularProgress, InputAdornment, TextField, TextFieldProps } from '@mui/material';

export type AsyncTextFieldProps = TextFieldProps & {
  loading: boolean;
};

export function AsyncTextField({ loading, ...textFieldProps }: AsyncTextFieldProps) {
  return (
    <TextField
      InputProps={{
        endAdornment: loading && (
          <InputAdornment position="end">
            <CircularProgress size="1rem" />
          </InputAdornment>
        ),
      }}
      fullWidth
      variant="standard"
      {...textFieldProps}
    />
  );
}
