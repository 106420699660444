import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { AsyncTextField } from '../../../atoms/async-text-field';

interface FormFields {
  name: string;
  address: string;
  rent: number;
  serviceFees: number;
  description: string;
}

interface PropertyBasicInfoSectionProps {
  fields: FormFields;
  onChange: (values: Partial<FormFields>) => void;
  updateLoading: boolean;
}

export function PropertyBasicInfoSection(props: PropertyBasicInfoSectionProps) {
  const { fields, onChange, updateLoading } = props;

  return (
    <Grid container spacing={2}>
      <Grid xs={12} lg={6}>
        <AsyncTextField
          loading={updateLoading}
          required
          value={fields.name}
          error={!fields.name}
          onChange={(e) => {
            const name = e.target.value;
            onChange({ name });
          }}
          label="Názov"
        />
      </Grid>
      <Grid xs={12} lg={6}>
        <AsyncTextField
          loading={updateLoading}
          required
          value={fields.address}
          error={!fields.address}
          onChange={(e) => {
            const address = e.target.value;
            onChange({ address });
          }}
          label="Adresa"
        />
      </Grid>
      <Grid xs={12} lg={6}>
        <AsyncTextField
          loading={updateLoading}
          required
          value={fields.rent}
          error={fields.rent <= 0}
          onChange={(e) => {
            const rent = Number(e.target.value) ?? 0;
            onChange({ rent });
          }}
          label="Nájomné"
        />
      </Grid>
      <Grid xs={12} lg={6}>
        <AsyncTextField
          loading={updateLoading}
          required
          value={fields.serviceFees}
          error={fields.serviceFees < 0}
          onChange={(e) => {
            const serviceFees = Number(e.target.value) ?? 0;
            onChange({ serviceFees });
          }}
          label="Služby a energie"
        />
      </Grid>
      <Grid xs={12}>
        <AsyncTextField
          loading={updateLoading}
          multiline
          rows={2}
          value={fields.description}
          onChange={(e) => {
            const description = e.target.value;
            onChange({ description });
          }}
          label="Opis nehnuteľnosti"
        />
      </Grid>
    </Grid>
  );
}
