import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  useDeletePropertyPhotoMutation,
  useGetPropertyLazyQuery,
  useUpdatePropertyMutation,
  useSetPropertyCoverPhotoMutation,
  useUpdatePropertyPhotoCaptionMutation,
  UpdatePropertyInput,
} from '../../_generated/graphql';
import { Stack, Typography, CircularProgress, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PropertyPictureView } from './components/property-picture-view';
import { BasePage } from '../../components/base-page';
import { DelayedWriter } from '../../lib/delayed-writer';
import { useNavigate } from 'react-router-dom';
import { appPaths } from '../../app-paths';
import { PropertyPhotoSection } from './components/property-photo-section';
import { PropertyCoverPhoto } from './components/cover-photo';
import { PropertyBasicInfoSection } from './components/property-basic-info-section';
import { PropertyFilesSection } from './components/property-files-section';

interface FormFields {
  name: string;
  address: string;
  rent: number;
  serviceFees: number;
  description: string;
}

const initialState: FormFields = {
  name: '',
  address: '',
  rent: 0,
  serviceFees: 0,
  description: '',
};

export function PropertyDetailPage() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [selectedPhoto, setSelectedPhoto] = useState<number | null>(null);
  const [fields, setFields] = useState<FormFields>(initialState);
  const [fetchProperty, { data, loading: initialLoading, error, refetch }] = useGetPropertyLazyQuery();

  const [setCoverPhoto] = useSetPropertyCoverPhotoMutation();
  const [deletePhoto] = useDeletePropertyPhotoMutation();
  const [updatePhotoCaption] = useUpdatePropertyPhotoCaptionMutation();

  const [updateProperty, { loading: updateLoading }] = useUpdatePropertyMutation();

  const handleUpdateRequest = useCallback(
    (input: UpdatePropertyInput) => {
      if (!id || !input) {
        return;
      }
      updateProperty({ variables: { id, input } });
    },
    [id, updateProperty],
  );
  const [delayedPropertyWriter] = useState(new DelayedWriter<UpdatePropertyInput>(handleUpdateRequest, 500));

  useEffect(() => {
    if (data?.getProperty) {
      const f: FormFields = {
        name: data.getProperty.name,
        address: data.getProperty.address,
        rent: data.getProperty.rent,
        serviceFees: data.getProperty.serviceFees,
        description: data.getProperty.description,
      };
      setFields(f);
    }
  }, [data]);

  useEffect(() => {
    if (id) {
      fetchProperty({ variables: { id } });
    }
  }, [id, fetchProperty]);

  const property = data?.getProperty;

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <BasePage>
      <Stack direction="row" spacing={0} sx={{ alignItems: 'center', marginBottom: '1.8rem' }}>
        <IconButton onClick={() => navigate(appPaths.propertyList())} sx={{ marginLeft: '-1rem' }}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant="h1" sx={{ margin: 0, marginLeft: '0.5rem' }}>
          Úprava nehnuteľnosti
        </Typography>
      </Stack>

      {initialLoading || !property ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h3">Základné informácie</Typography>
          <PropertyBasicInfoSection
            fields={fields}
            onChange={(v) => {
              setFields((s) => ({ ...s, ...v }));
              delayedPropertyWriter.write(v);
            }}
            updateLoading={updateLoading}
          />

          <Grid container>
            <Grid xs={12} sm={6}>
              <PropertyCoverPhoto property={property} />
            </Grid>
          </Grid>

          <Typography variant="h3">Fotky</Typography>
          <PropertyPhotoSection
            property={property}
            onUploadCompleted={() => refetch()}
            onSelectPhoto={setSelectedPhoto}
          />

          <Typography variant="h3">Dokumenty</Typography>
          <PropertyFilesSection
            propertyId={property.id}
            files={property.propertyFiles}
            type={'pdp'}
            uploadButtonLabel="Pridaj dokument"
            onUploadCompleted={() => refetch()}
          />

          <Typography variant="h3">Dokumenty pre nájomníkov</Typography>
          <PropertyFilesSection
            propertyId={property.id}
            files={property.filesForTenant}
            type={'pdt'}
            uploadButtonLabel="Pridaj dokument pre nájomníka"
            onUploadCompleted={() => refetch()}
          />

          {selectedPhoto !== null && (
            <PropertyPictureView
              property={property}
              selectedPhoto={selectedPhoto}
              onClose={() => setSelectedPhoto(null)}
              onSetAsCoverPhoto={(photoId) => {
                setCoverPhoto({ variables: { id: property.id, photoId } });
                setSelectedPhoto(null);
              }}
              onDeletePhoto={(photoId) => {
                deletePhoto({ variables: { id: property.id, photoId } });
                setSelectedPhoto(null);
              }}
              onCaptionChange={(photoId, caption) => {
                updatePhotoCaption({ variables: { id: property.id, photoId, caption } });
              }}
            />
          )}
        </>
      )}
    </BasePage>
  );
}
