import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField } from '@mui/material';
import React, { useState } from 'react';

interface NewPropertyDialogProps {
  loading: boolean;
  onClose: () => void;
  onSubmit: (propertyName: string) => void;
}

export function NewPropertyDialog(props: NewPropertyDialogProps) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const handleSubmit = () => {
    if (name && !nameError) {
      props.onSubmit(name);
    } else {
      setNameError(true);
    }
  };

  return (
    <Dialog open onClose={props.onClose} fullWidth maxWidth="xs">
      <DialogTitle>Nová nehnuteľnosť</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          error={nameError}
          label="Názov"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
            setNameError(!event.target.value);
          }}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Zrušiť</Button>
        <Button type="submit" onClick={handleSubmit}>
          Pridať
        </Button>
      </DialogActions>
      {props.loading && <LinearProgress />}
    </Dialog>
  );
}
